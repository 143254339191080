// configuratie-rekening component //
<template>
  <div class="fin-regel">
    <div class="fin-flex-row"
         v-bind:class="{ 'cfg-rek-regel-hover': hover }"
         @mouseover="hover = true"
         @mouseleave="hover = false"
         >

      <!-- Drag & Drop icon -->
      <div v-bind:class="classDragDrop"
           v-bind:id="categorie.id"
           @mouseover="isDraggable = true"
           @mouseleave="isDraggable = false"
           >
          <i class="material-icons bk-16"
             v-bind:id="categorie.id"
             >
             drag_indicator
          </i>
      </div>

      <!-- Spacer -->
      <div class="fin-spacer fw2">
      </div>

      <!-- Edit knop -->
      <div v-bind:id="categorie.id"
           v-bind:class="classEdit"
           v-on:click="editCategorie"
           >
          <i class="material-icons bk-16"
             v-bind:id="categorie.id"
             >
             edit
          </i>
      </div>

      <!-- Categorie naam -->
      <div class="fin-regel-cel" style="width:250px;">
        {{ categorie.naam }}
      </div>

      <!-- Spacer -->
      <div class="fin-spacer" style="width:20px;">
      </div>

      <!-- Aktief indicator -->
      <div v-bind:class="classActive">
          <i class="material-icons bk-16">
             check_box
          </i>
      </div>

      <!-- Spacer -->
      <div class="fin-spacer" style="width:40px;">
      </div>

      <!-- has Subcat indicator -->
      <div v-bind:class="classHasSubcat">
          <i class="material-icons bk-16">
             check_box
          </i>
      </div>

    </div>

    <div class="fin-divider fbg-subbase fh3">
    </div>

  </div>
</template>

<script>

export default {
  props: ['categorie'],

  computed: {
    classDragDrop () {
      return {
        'fin-regel-icon' : true,
        'invisible' : !this.hover,
        'dragdrop'   : this.hover
      }
    },
    classEdit () {
      return {
         'fin-regel-icon' : true,
         'invisible' : !this.hover,
         'enabled'   : this.hover
       }
    },
    classActive () {
      return {
        'fin-regel-icon' : true,
        'invisible' : !this.categorie.isActive,
        'shaded': this.categorie.isActive
      }
    },
    classHasSubcat () {
      return {
        'fin-regel-icon' : true,
        'invisible' : !this.categorie.hasSubcat,
        'shaded': this.categorie.hasSubcat
      }
    },
  },

  data() {
    return {
      hover: false,
      // isDraggable: false  // TODO: Nog koppelen aan de draag & drop logica (als mogelijk)
    }
  },

  methods: {
    // setRekeningDefault: function() {
    // if (event) {
    //     this.$store.dispatch('rekening/setDefault', {id: event.target.id});
    //  }
    // },
    //
    editCategorie: function() {
      if (event) {
        alert('Edit categorie')
        // this.$store.commit('rekening/setIsEditedId', event.target.id);
        // this.$store.commit('rekening/toggleIsEditschermTonen');
      }
    },
    // toggleIsInFilter: function() {
    // if (event) {
    //     this.$store.dispatch('rekening/toggleIsInFilter', {id: event.target.id});
    //   }
    // }
  }
}
</script>

<style scoped>

.material-icons.bk-16 {
  font-size: 16px;
}

</style>
