// configuratie-rekening component //
<template>
  <div class="fin-regel">
    <div class="fin-flex-row"
         v-bind:class="{ 'fin-regel-hover': hover }"
         @mouseover="hover = true"
         @mouseleave="hover = false"
         >

      <!-- Drag & Drop icon -->
      <div v-bind:class="classDragDrop"
           v-bind:id="rekening.id"
           @mouseover="isDraggable = true"
           @mouseleave="isDraggable = false"
           >
          <i class="material-icons bk-16"
             v-bind:id="rekening.id"
             >
             drag_indicator
          </i>
      </div>

      <!-- Spacer -->
      <div class="fin-spacer fw2">
      </div>

      <!-- Edit knop -->
      <div v-bind:id="rekening.id"
           v-bind:class="classEdit"
           v-on:click="editRekening"
           >
          <i class="material-icons bk-16"
             v-bind:id="rekening.id"
             >
             edit
          </i>
      </div>

      <!-- Spacer -->
      <div class="fin-spacer fw2">
      </div>

      <!-- Rekeningnaam -->
      <div style="width:200px;"
           class="fin-regel-cel"
           >
           {{ rekening.naam }}
      </div>

      <!-- Rekeningnummer -->
      <div style="width:180px;"
           class="fin-regel-cel"
           >
           {{ rekening.nummer }}
      </div>

      <!-- Partij -->
      <div style="width:150px;"
           class="fin-regel-cel"
           >
           {{ rekening.partij }}
      </div>

      <!-- Spacer -->
      <div class="fin-spacer" style="width:20px;">
      </div>

      <!-- Aktief indicator -->
      <div v-bind:class="classAktief">
          <i class="material-icons bk-16">
             check_box
          </i>
      </div>

      <!-- Spacer -->
      <div class="fin-spacer" style="width:40px;">
      </div>

      <!-- In filter indicator -->
      <div v-bind:id="rekening.id"
           v-bind:class="classInFilter"
           v-on:click="toggleIsInFilter"
           >
          <i class="material-icons bk-16"
             v-bind:id="rekening.id"
             >
             {{ iconInFilter }}
          </i>
      </div>

      <!-- Spacer -->
      <div class="fin-spacer" style="width:40px;">
      </div>

      <!-- Default selected indicator -->
      <div v-bind:id="rekening.id"
           v-bind:class="classIsDefault"
           v-on:click="setRekeningDefault"
           >
          <i class="material-icons bk-16"
             v-bind:id="rekening.id"
             >
             {{ iconIsDefault }}
          </i>
      </div>

    </div>

    <!-- divider -->
    <div class="fin-divider fbg-subbase fh3">
    </div>

  </div>
</template>

<script>

export default {
  props: ['rekening'],

  computed: {
    classDragDrop () {
      return {
        'fin-regel-icon' : true,
        'invisible' : !this.hover,
        'dragdrop'   : this.hover
      }
    },
    classEdit () {
      return {
         'fin-regel-icon' : true,
         'invisible' : !this.hover,
         'enabled'   : this.hover
       }
    },
    classAktief () {
      return {
        'fin-regel-icon' : true,
        'invisible' : !this.rekening.isAktief,
        'shaded': this.rekening.isAktief
      }
    },
    classInFilter () {
      return {
        'fin-regel-icon' : true,
        'invisible' : !this.rekening.isInFilter && !this.hover,
        'enabled': !this.rekening.isInFilter && this.hover,
        'shaded': this.rekening.isInFilter && this.rekening.isDefaultSelectie,
        'selected': this.rekening.isInFilter && !this.rekening.isDefaultSelectie
      }
    },
    classIsDefault () {
      return {
        'fin-regel-icon' : true,
        'invisible': !this.rekening.isDefaultSelectie && (
                      (!this.rekening.isInFilter && !this.hover) ||
                      (!this.rekening.isInFilter && this.hover) ||
                      (this.rekening.isInFilter && !this.hover)
                    ),
        'enabled': !this.rekening.isDefaultSelectie && this.rekening.isInFilter && this.hover,
        'locked': this.rekening.isDefaultSelectie
      }
    },
    iconInFilter () {
      if (this.rekening.isInFilter) {
        return 'check_box'
      } else {
        return 'check_box_outline_blank'
      }
    },
    iconIsDefault () {
      if (this.rekening.isDefaultSelectie) {
        return 'check_box'
      } else {
        return 'check_box_outline_blank'
      }
    },
  },

  data() {
    return {
      hover: false,
      isDraggable: false  // TODO: Nog koppelen aan de draag & drop logica (als mogelijk)
    }
  },

  methods: {
    setRekeningDefault: function() {
      if (event) {
        if (this.rekening.isInFilter && !this.rekening.isDefaultSelectie) {
          this.$store.dispatch('rekening/setDefault', {id: event.target.id});
        }
      }
    },
    editRekening: function() {
      if (event) {
        this.$store.commit('rekening/setIsEditedId', event.target.id);
        this.$store.commit('rekening/toggleIsEditschermTonen');
      }
    },
    toggleIsInFilter: function() {
      if (event) {
        if (!this.rekening.isDefaultSelectie) {
          this.$store.dispatch('rekening/toggleIsInFilter', {id: event.target.id});
        }
      }
    }
  }
}
</script>

<style scoped>

.material-icons.bk-16 {
  font-size: 16px;
}

</style>
