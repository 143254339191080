// Login component //

<template>
  <div class="full-screen-container">
    <div class="login-container">

      <h3 class="login-title" @click="quickLogin">
        Welkom bij Fin
      </h3>

      <div class="login-message" v-if="autologout">
        Je bent automatisch uitgelogd.
      </div>

      <form @submit.prevent="login">
        <div class="input-group">
          <label>Inlognaam</label>
          <input v-model="userName" name="userName" label="Inlognaam" type="text" required>
        </div>
        <div class="input-group">
          <label>Wachtwoord</label>
          <input v-model="password" name="password" label="Wachtwoord" id="password" type="password" required>
        </div>
        <!-- <div class="input-group">
          <label>Database</label>
          <select v-model="databaseAlias" name="databaseAlias">
            <option>Default</option>
            <option>Demo</option>
          </select>
        </div> -->
        <button type="submit" class="login-button" @click="login">Log in</button>
      </form>

    </div>
  </div>
</template>


<script>
export default {
  name: 'login',

  data () {
    return {
      // No need to make this available outside this component
      userName: '',
      password: '',
    }
  },

  computed: {
    authenticated () {
      return this.$store.getters['auth/authenticated'];
    },
    autologout () {
      return this.$store.getters['auth/autologout'];
    },
    // databaseAlias: {
    //   get: function () {
    //     return this.$store.getters['auth/databaseAlias'];
    //   },
    //   set: function (newValue) {
    //     this.$store.commit('auth/databaseAlias', newValue);
    //   }
    // },
  },

  watch: {
    authenticated (value) {
      // when login succeeds then automatically go to main page
      if (value) {
        this.$store.commit('nav/currentComponent', 'Boekingen');
        this.$router.push('/');
      }
    }
  },

  methods: {
    login () {
      this.$store.dispatch('auth/loginUser',{userName: this.userName.toLowerCase(),
                                             password: this.password,
                                             databaseAlias: this.databaseAlias
                                            }
      );
    },
    quickLogin () {
      this.$store.dispatch('auth/loginUser',{userName: 'arnoud',
                                             password: 'geheim',
                                             databaseAlias: 'default'
                                            }
      );
    },
  }
}
</script>


<style scoped>

.full-screen-container {
  background-image: url("../../assets/fin-background-login-1920.jpg");
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  background-color: var(--overflow);
  padding: 50px 30px;
  min-width: 400px;
  width: 50%;
  max-width: 400px;
}

.login-message {
  background-color: var(--accent-1-hover);
  color: var(--text-main-brighter);
  text-align: center;
  margin: 10px 0px 10px 0px;
  font-size: 2.5em;
  font-weight: normal;
}

.login-title {
  color: var(--text-main-brighter);
  text-align: center;
  margin: 0px;
  margin-bottom: 40px;
  font-size: 2.5em;
  font-weight: normal;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.input-group label {
  color: var(--text-main);
  font-weight: lighter;
  font-size: 1.5em;
  margin-bottom: 7px;
}

.input-group input {
  font-size: 1.5em;
  padding: .1em .25em;
  background-color: hsla(201, 100%, 91%, 0.3);
  border: 1px solid var(--accent-1-partial);
  outline: none;
  border-radius: 5px;
  color: var(--text-main);
  font-weight: lighter;
}

.input-group input:focus {
  border: 1px solid var(--accent-1-full);
}

.login-button {
  padding: 10px 30px;
  width: 100%;
  border-radius: 5px;
  background-color: var(--accent-1-partial);
  border: none;
  outline: none;
  font-size: 1.5em;
  color: var(--text-main);
  font-size: lighter;
  margin-top: 20px;
  cursor: pointer;
}

.login-button:hover {
  background-color: var(--accent-1-full);
}

.login-button:focus {
  background-color: var(--accent-1-full);
}

</style>
