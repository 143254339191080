// Store module boeking //
import Vue     from 'vue';
import axios   from 'axios';
const axiosUrl = '/api/data/v1/boeking';

// function compareJaarMaand(a, b) {
//   // descending sort order //
//   let comparison = 0;
//   if (a.jaarmaand > b.jaarmaand) {
//     comparison = -1;
//   } else if (a.jaarmaand < b.jaarmaand) {
//     comparison = 1;
//   }
//   return comparison;
// };

function compareJaartal(a, b) {
  // descending sort order //
  let comparison = 0;
  if (a.jaartal > b.jaartal) {
    comparison = -1;
  } else if (a.jaartal < b.jaartal) {
    comparison = 1;
  }
  return comparison;
};


// function compareAtoBdesc(a, b) {
//   // descending sort order //
//   let comparison = 0;
//   if (a > b) {
//     comparison = -1;
//   } else if (a < b) {
//     comparison = 1;
//   }
//   return comparison;
// };

function compareBoekingenDatum(a, b) {
  // descending sort order //
  var pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
  let DatumA = new Date(a.datum.replace(pattern,'$3-$2-$1'));
  let DatumB = new Date(b.datum.replace(pattern,'$3-$2-$1'));
  let comparison = 0;

  if (DatumA > DatumB) {
    comparison = -1;
  } else if (DatumA < DatumB) {
    comparison = 1;
  }
  return comparison;
};


const stateInit = {

  serviceEnv: '',
  //sortOrder: [0],
  boekingen: {
    0 : {
      id: 0,
      rekeningnaam: 'Default',
      datum: null,
      boekjaarmaand: '',
      boekjaar: '',
      boekmaandnaam: '',
      vanaan: '',
      omschrijving: '',
      bedrag_bij: 0,
      bedrag_af: 0,
      boekingbedrag: 0,
      transactiehoofdtype: 'Standaard',
      transactietype: 'Standaard',
      categorie: '',
      subcategorie: '',
      kruispostkenmerk: '',
      tegenrekeningnaam: '',
      voorschotomschrijving: '',
      voorschotpartij: '',
      voorschotstatus: '',
      groepomschrijving: '',
      boekingstatus: 'Closed',
      rek_id: 0,
      isDefaultSelectie: true,
      cat_id: 0,
      scat_id: 0,
      vs_id: 0,
      grp_id: 0,
      isSelected: false,
      isStarred: false,
      isMeetellenVw: true,
      isEigenDeel: false
   }
  },
  filterMaanden: {
    0: {
      jaartal: 0,
      isExpanded: true,
      maanden: {
        jaarmaand: '',
        maandnaam : '',
        isSelected : true
      }
    }
  },
  filterRekening: 'Default',
  filterRekeningId: 0,
  filterSelectedOnly: false,
  filterStarredOnly: false,

  boekingStatussen: {
    'Open'     : { naam : 'Open'     , isSelected : true },
    'Complete' : { naam : 'Complete' , isSelected : true },
    'Closed'   : { naam : 'Closed'   , isSelected : true }
  },

  transactieTypes:{
    'Standaard' : { naam : 'Standaard' , isSelected : true },
    'Kruispost' : { naam : 'Kruispost' , isSelected : true },
    'Voorschot' : { naam : 'Voorschot' , isSelected : true },
    // 'Retour voorschot' : { naam : 'Retour voorschot' , isSelected : true },
    'Belegging' : { naam : 'Belegging' , isSelected : true }
  },
  boekingenZichtbaar: [],
  isInvoerschermTonen: false,
  //isAktiefChangeCounter: 0,
  //isEditschermTonen: false,
  //isEditedId: '',
}

const state = stateInit;

const getters = {

  boekingen(state) {
    //  Return only the filtered data

    let   arrBoekingen = []
    const mapSelectie = new Map

    // Filter op rekening //
    for (const key in state.boekingen) {
      if (state.boekingen[key].rekeningnaam === state.filterRekening) {
        if (!mapSelectie.has(key)) {mapSelectie.set(key, true)}
      }
    }

    // Filter op jaarmaand //
    const mapJaarmaand = new Map
    for (const jaarKey in state.filterMaanden) {
      let tmpJaar = state.filterMaanden[jaarKey]
      for (const maandKey in tmpJaar.maanden) {
        if (tmpJaar.maanden[maandKey].isSelected) {
          mapJaarmaand.set(tmpJaar.maanden[maandKey].jaarmaand, true)
        }
      }
    }
    for (const key of mapSelectie.keys()) {
      if (!mapJaarmaand.has(state.boekingen[key].boekjaarmaand)) {
        mapSelectie.delete(key)
      }
    }

    // Filter op selected //
    // if filterSelectedOnly, dan alleen boekingen met isSelected = true
    if (state.filterSelectedOnly) {
      for (const key of mapSelectie.keys()) {
        if (!state.boekingen[key].isSelected) {
          mapSelectie.delete(key)
        }
      }
    }

    // Filter op starred //
    // if filterSelectedOnly, dan alleen boekingen met isSelected = true
    if (state.filterStarredOnly) {
      for (const key of mapSelectie.keys()) {
        if (!state.boekingen[key].isStarred) {
          mapSelectie.delete(key)
        }
      }
    }

    // Filter op transactietype //
    const mapTransactieTypes = new Map
    for (const key in state.transactieTypes) {
      if (state.transactieTypes[key].isSelected) {
        mapTransactieTypes.set(state.transactieTypes[key].naam, true)
      }
    }
    for (const key of mapSelectie.keys()) {
      if (!mapTransactieTypes.has(state.boekingen[key].transactiehoofdtype)) {
        mapSelectie.delete(key)
      }
    }

    // Filter op boekingStatus //
    const mapBoekingStatussen = new Map
    for (const key in state.boekingStatussen) {
      if (state.boekingStatussen[key].isSelected) {
        mapBoekingStatussen.set(state.boekingStatussen[key].naam, true)
      }
    }
    for (const key of mapSelectie.keys()) {
      if (!mapBoekingStatussen.has(state.boekingen[key].boekingstatus)) {
        mapSelectie.delete(key)
      }
    }

    // Stel de array samen uit de overgebleven keys //
    for (const item of mapSelectie.keys()) {
      // console.log(item + ' = '+ mapSelectie.get(item))
      if (mapSelectie.get(item)) {
        arrBoekingen.push(state.boekingen[item])
      }
    }

    // Make sure the output is always sorted on Datum descending
    // Maybe later other sort orders
    arrBoekingen = arrBoekingen.sort(compareBoekingenDatum);
    return arrBoekingen;
  },

  isInvoerschermTonen(state) {
    return state.isInvoerschermTonen;
  },

  serviceEnv(state) {
    return state.serviceEnv;
  },

  filterRekening(state) {
    return state.filterRekening;
  },

  filterRekeningId(state) {
    return state.filterRekeningId;
  },

  maandenVoorFilter(state) {
    let arrResult = []
    for (const item in state.filterMaanden) {
      let jaarItem = state.filterMaanden[item]
        let jaar = {
          jaartal: jaarItem.jaartal,
          isJaarSelected: jaarItem.maanden.every(function(el){ return el.isSelected === true;}),
          isJaarPartialSelected: jaarItem.maanden.some(function(el){ return el.isSelected === true;}) && !jaarItem.maanden.every(function(el){ return el.isSelected === true;}),
          isExpanded: jaarItem.isExpanded,
          maanden: jaarItem.maanden
        }
        arrResult.push(jaar)
    }
    // De maanden worden in volgorde aangeleverd
    // maar door object met index worden de jaartallen weer oplopend gesorteerd, dus alsnog sorteren.
    arrResult = arrResult.sort(compareJaartal)
    return arrResult
  },

  filterSelectedOnly(state) {
    return state.filterSelectedOnly
  },

  filterStarredOnly(state) {
    return state.filterStarredOnly
  },

  boekingStatussen(state) {
    return state.boekingStatussen
  },

  transactieTypes(state) {
    return state.transactieTypes
  },

}

const mutations = {

  // Internal only //

  __boekingToevoegen(state, boeking) {
    Vue.set(state.boekingen, boeking.id, boeking);
  },

  __boekingDelete(state, id) {
    Vue.delete(state.boekingen, id);
  },

  __filterRekening(state, rekeningnaam) {
    state.filterRekening = rekeningnaam;
  },

  __filterRekeningId(state, rekeningid) {
    state.filterRekeningId = rekeningid;
  },

  __filterJaarToevoegen(state, filterJaar) {
    Vue.set(state.filterMaanden, filterJaar.jaartal, filterJaar);
  },

  __filterJaarDelete(state, filterJaar) {
    Vue.delete(state.filterMaanden, filterJaar);
  },

  __toggleStarred(state, id) {
  // TODO: Vue.set
    state.boekingen[id].isStarred = !state.boekingen[id].isStarred;
  },

  // External //

  setFilterRekening(state, filterrekening) {
    const arrSplit = filterrekening.split("$");
    state.filterRekeningId = arrSplit[0]
    state.filterRekening = arrSplit[1]
  },

  isInvoerschermTonen(state, value) {
    state.isInvoerschermTonen = value;
  },

  serviceEnv(state, serviceEnv) {
    state.serviceEnv = serviceEnv;
  },

  toggleSelected(state, id) {
  // TODO: Vue.set
    state.boekingen[id].isSelected = !state.boekingen[id].isSelected;
  },

  filterMaandSelect(state, jaarmaand) {
    const jaartal = parseInt(jaarmaand.substring(0, 4));
    let maanden = state.filterMaanden[jaartal].maanden
    maanden.forEach( (item) => {
      if (item.jaarmaand === jaarmaand) {
        item.isSelected = true
      }
    })
    Vue.set(state.filterMaanden[jaartal], 'maanden', maanden)
  },

  filterMaandDeselect(state, jaarmaand) {
    const jaartal = parseInt(jaarmaand.substring(0, 4));
    let maanden = state.filterMaanden[jaartal].maanden
    maanden.forEach( (item) => {
      if (item.jaarmaand === jaarmaand) {
        item.isSelected = false
      }
    })
    Vue.set(state.filterMaanden[jaartal], 'maanden', maanden)
  },

  filterJaarSelect(state, jaartal) {
    let maanden = state.filterMaanden[jaartal].maanden
    maanden.forEach( (item) => {
        item.isSelected = true
    })
    Vue.set(state.filterMaanden[jaartal], 'maanden', maanden)
  },

  filterJaarDeselect(state, jaartal) {
    let maanden = state.filterMaanden[jaartal].maanden
    maanden.forEach( (item) => {
        item.isSelected = false
    })
    Vue.set(state.filterMaanden[jaartal], 'maanden', maanden)
  },

  filterJaarExpand(state, jaartal) {
    Vue.set(state.filterMaanden[jaartal], 'isExpanded', true)
  },

  filterJaarCollapse(state, jaartal) {
    Vue.set(state.filterMaanden[jaartal], 'isExpanded', false)
  },

  filterToggleStatus(state, id) {
    Vue.set(state.boekingStatussen[id], 'isSelected', !state.boekingStatussen[id].isSelected)
  },

  filterToggleType(state, id) {
    Vue.set(state.transactieTypes[id], 'isSelected', !state.transactieTypes[id].isSelected)
  },

  toggleFilterSelectedOnly(state) {
    state.filterSelectedOnly = !state.filterSelectedOnly
  },

  toggleFilterStarredOnly(state) {
    state.filterStarredOnly = !state.filterStarredOnly
  },

}

const actions = {

  initBoekingen ({commit}, payload) {
    const url = axiosUrl
    let strTransactiehoofdtype = ''
    let isDefaultCommitted = false

    axios.get(url)
    .then(response => {
      // Save the environment variable //
      commit('serviceEnv', response.data.env);

      // Create a new boeking object //
      response.data.boekingen.forEach( (item) => {

        // part 1, the data //
        if (item.transactietype==='Retour voorschot') {
          strTransactiehoofdtype = 'Voorschot'
        } else {
          strTransactiehoofdtype = item.transactietype
        }
        let boeking = {
          id: item.id,
          rekeningnaam: item.rekeningnaam,
          datum: item.datum,
          boekjaarmaand: item.boekjaarmaand,
          boekjaar: item.boekjaar,
          boekmaandnaam: item.boekmaandnaam,
          vanaan: item.vanaan,
          omschrijving: item.omschrijving,
          boekingbedrag: item.boekingbedrag,
          bedrag_bij: item.bedrag_bij,
          bedrag_af: item.bedrag_af,
          transactiehoofdtype: strTransactiehoofdtype,
          transactietype: item.transactietype,
          categorie: item.categorie,
          subcategorie: item.subcategorie,
          kruispostkenmerk: item.kruispostkenmerk,
          tegenrekeningnaam: item.tegenrekeningnaam,
          voorschotomschrijving: item.voorschotomschrijving,
          voorschotpartij: item.voorschotpartij,
          voorschotstatus: item.voorschotstatus,
          groepomschrijving: item.groepomschrijving,
          boekingstatus: item.boekingstatus,
          rek_id: item.rek_id,
          isDefaultSelectie: item.isDefaultSelectie,
          cat_id: item.cat_id,
          scat_id: item.scat_id,
          vs_id: item.vs_id,
          grp_id: item.grp_id,
          isSelected: false,      // default altijf false, komt niet uit de database
          isStarred: item.isStarred,
          isMeetellenVw: item.isMeetellenVw,
          isEigenDeel: item.isEigenDeel
        };
        commit('__boekingToevoegen', boeking);

        // part 2, Set the default rekening //
        if (item.isDefaultSelectie && !isDefaultCommitted) {
            commit('__filterRekening', item.rekeningnaam);
            commit('__filterRekeningId', item.rek_id);
            isDefaultCommitted = true
        }
      });
      // Remove the default item and its filters//
      commit('__boekingDelete', 0);
    })
    .catch(
      // dit is bij een technische fout
      error => {
        console.log(error);
      }
    )
  },

  initFilterMaanden ({commit}, payload) {
    const url = axiosUrl + '/filtermaanden'
    axios.get(url)
    .then(response => {
      response.data.forEach( (item) => {
        commit('__filterJaarToevoegen', item);
      })
      commit('__filterJaarDelete', 0);
    })
    .catch(
      // dit is bij een technische fout
      error => {
        console.log(error);
      }
    )
  },

  toggleStarred ({commit}, payload) {
    const url = axiosUrl + '/toggleisstarred/' + payload.id
    axios.put(url)
    .then(response => {
      //update the local version if succesful
      // TODO: check response for error text first!!!!!
      commit('__toggleStarred', payload.id);
    })
    .catch(
      // dit is bij een technische fout
      error => {
        console.log(error);
      }
    )
  },

}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
