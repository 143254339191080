import Vue          from 'vue';
import Vuex         from 'vuex';

import auth         from './modules/auth';
import nav          from './modules/nav';
import rekening     from './modules/rekening';
import boeking      from './modules/boeking';
import categorie    from './modules/categorie';

Vue.use(Vuex);

export const store = new Vuex.Store({

  modules: {
    auth,
    nav,
    rekening,
    boeking,
    categorie
  },

  state: {
// TODO: p gaan halen uit de PM2 config via /$config etc //
    appName: 'fin',
    appVersion: '0.1.0',
    appEnv: 'Development',
  },

  getters: {
    appName(state) {
      return state.appName;
    },
    appVersion(state) {
      return state.appVersion;
    },
    appEnv(state) {
      return state.appEnv;
    },
  },

  mutations: {
  },
})
