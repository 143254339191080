// Boekingen component //
<template>
  <div class="fin-full-screen-container">

    <!-- Modal -->
    <app-boekingeninvoeren></app-boekingeninvoeren>

    <app-header></app-header>

    <div class="fin-left-sidebar">
      <app-boekingen-left></app-boekingen-left>
    </div>

<!-- nagaan of deze nesting nodig is : container en content -->

    <div id="container" class="container">
      <div id="content">
        <app-boekingenbody></app-boekingenbody>
      </div>
    </div>

  </div>
</template>

<script>
  import header            from '../general/header.vue';
  import boekingenleft     from './boekingen-left.vue';
  import boekingenbody     from './boekingen-body.vue';
  import boekingeninvoeren from './invoeren/bkng-invoeren.vue';

  export default {
    components: {
      'app-header'            : header,
      'app-boekingen-left'    : boekingenleft,
      'app-boekingenbody'     : boekingenbody,
      'app-boekingeninvoeren' : boekingeninvoeren,
    },

    data() {
      return {
      }
    },

  }
</script>

<style scoped>

.container {
    position: absolute;
    top: 50px;
    left: 150px;
    width: 100%;
    height: 100%;
    flex: 1 0 auto;
    margin: 0;
    text-align: left;
}

#content {
    width: calc(100% - 150px);
    height: 100%;
    border-radius: 0;
    border: 0;
    margin: 0;
    box-sizing: border-box;
}
</style>
