// configuratie-rekeningen component //
<template>
  <div class="cfg-body-container">

    <app-rekening-edit></app-rekening-edit>

    <app-rekening-invoeren></app-rekening-invoeren>

    <div class="cfg-rek-header">
      <div class="cfg-rek-headericon--cell" style="width:44px;">
        &nbsp; <!-- voor hover en edit -->
      </div>
      <div class="cfg-rek-header--cell" style="width:200px;">
        Rekeningnaam
      </div>
      <div class="cfg-rek-header--cell" style="width:180px;">
        Rekeningnummer
      </div>
      <div class="cfg-rek-header--cell" style="width:150px;">
        Partij
      </div>
      <div class="cfg-rek-headericon--cell fin-centered" style="width:60px;">
        Aktief
      </div>
      <div class="cfg-rek-headericon--cell fin-centered" style="width:60px;">
        In filter
      </div>
      <div class="cfg-rek-headericon--cell fin-centered" style="width:60px;">
        Default
      </div>
    </div>

    <div class="fin-divider fbg-text-main-darker fh1">
    </div>
    <div class="fin-divider fh4">
    </div>

<!-- !!!voorbeeld!!!
    <draggable v-model="myArray" group="people" @start="drag=true" @end="drag=false">
       <div v-for="element in myArray" :key="element.id">{{element.name}}</div>
    </draggable>
-->

    <!-- <div v-bind:class="{ 'tmp' : this.drag}">drag</div> -->

    <div class="rekeningenlijst">
      <draggable v-model="rekeningen" @start="drag=true" @end="drag=false">
        <div v-for="rekening in rekeningen" :key="rekening.id">
          <div class="rekeningregel">
            <app-rekening-regels v-bind:rekening="rekening"></app-rekening-regels>
          </div>
        </div>
      </draggable>
    </div>

    <span class="fin-buttonlist">
      <div v-bind:class="['fin-button', !rekeningInvoerTonen ? 'enabled' : 'disabled']"
            v-on:click="openInvoerscherm"
            >
            <!-- v-bind:class="{ 'fin-button-active' : !rekeningInvoerTonen }" -->
        <i class="material-icons">add</i>
      </div>
    </span>

  </div>
</template>

<script>
import draggable        from 'vuedraggable';
import rekeningregels   from './cfg-rekening-regels.vue';
import rekeningedit     from './cfg-rekening-edit.vue';
import rekeninginvoeren from './cfg-rekening-invoeren.vue';

export default {

  components: {
    draggable,
    'app-rekening-regels'   : rekeningregels,
    'app-rekening-edit'     : rekeningedit,
    'app-rekening-invoeren' : rekeninginvoeren,
  },

  computed: {
    rekeningen () {
      return this.$store.getters['rekening/rekeningen'];
    },
  // TODO: zou hier niet ook een getter voor rekeningInvoerTonen moeten zijn?
  },

  data() {
    return {
      drag: false
    }
  },

  methods: {
    openInvoerscherm: function() {
      if (!this.rekeningInvoerTonen) {
        this.$store.commit('rekening/toggleIsInvoerschermTonen');
      }
    }
  }
}
</script>

<style scoped>

.tmp {
  background-color:  green;
}

.cfg-body-container {
  width: 100%;
  height: 100%;
  padding: 10px 8px 8px 15px;
  color: var(--text-main);
  background-color: var(--subbase);
}

.cfg-rek-header {
  display: flex;
  flex-flow: row nowrap;
  color: var(--text-main-darker);
  font-size: 15px;
  font-weight: 300;
  padding: 2px 0px 2px 0px;
}

.cfg-rek-headericon--cell {
   padding: 2px 0px 2px 0px;
   margin: 0px;
}

.cfg-rek-header--cell {
   padding: 2px;
}


</style>
