import Vue          from 'vue'
import { router }   from '../router'
import { store }    from '../store/store'
import App          from '../components/app.vue'
import numeriek     from '../filters/numeriek2'
import toJaNee      from '../filters/boolean-to-ja-nee'

Vue.config.productionTip = false;

Vue.filter('numeriek2decimalen', numeriek)
Vue.filter('booleanToJaNee', toJaNee)

new Vue({
  el: 'app',
  router: router,
  store: store,
  render: h => h(App)
});
