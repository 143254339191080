// configuratie-categorie component //
<template>
  <div class="cfg-body-container">
    <div class="cfg-rek-header">
      <div class="cfg-rek-headericon--cell" style="width:44px;">
        &nbsp; <!-- voor hover en edit -->
      </div>
      <div class="cfg-rek-header--cell" style="width:250px;">
        Categorie
      </div>
      <div class="cfg-rek-headericon--cell fin-centered" style="width:60px;">
        Aktief
      </div>
      <div class="cfg-rek-headericon--cell fin-centered" style="width:60px;">
        Subcat
      </div>
    </div>

    <div class="fin-divider fbg-text-main-darker fh1">
    </div>
    <div class="fin-divider fh4">
    </div>

    <div class="rekeningenlijst">
      <div v-for="categorie in categorien" :key="categorie.id">
        <div class="rekeningregel">
          <app-categorie-regels v-bind:categorie="categorie"></app-categorie-regels>
        </div>
      </div>
    </div>

    <span class="fin-buttonlist">
      <div v-bind:class="['fin-button', !rekeningInvoerTonen ? 'enabled' : 'disabled']"
            v-on:click="openInvoerscherm"
            >
            <!-- v-bind:class="{ 'fin-button-active' : !rekeningInvoerTonen }" -->
        <i class="material-icons">add</i>
      </div>
    </span>

  </div>
</template>

<script>
import categorieregels   from './cfg-categorie-regels.vue';

export default {

  components: {
    'app-categorie-regels'   : categorieregels
  },

  computed: {
    categorien () {
      return this.$store.getters['categorie/categorien'];
    },
  },

  data() {
    return {
    }
  },

  methods: {
    openInvoerscherm: function() {
      if (!this.rekeningInvoerTonen) {
        this.$store.commit('categorie/toggleIsInvoerschermTonen');
      }
    }
  }
}
</script>

<style scoped>


.cfg-body-container {
  width: 100%;
  height: 100%;
  padding: 10px 8px 8px 15px;
  color: var(--text-main);
  background-color: var(--subbase);
}

.cfg-rek-header {
  display: flex;
  flex-flow: row nowrap;
  color: var(--text-main-darker);
  font-size: 15px;
  font-weight: 300;
  padding: 2px 0px 2px 0px;
}

.cfg-rek-headericon--cell {
   padding: 2px 0px 2px 0px;
   margin: 0px;
}

.cfg-rek-header--cell {
   padding: 2px;
}


</style>
