// Boekingen component //
<template>
  <div class="fin-full-screen-container">
    <app-header></app-header>
    <div id="leftside" class="fin-left-sidebar">
      <app-configuratie-left v-bind:currentConfigItem="currentConfigItem"></app-configuratie-left>
    </div>
    <div id="container" class="container">
      <app-configuratie-rekeningen v-if="currentConfigItem === 'rekeningen'"></app-configuratie-rekeningen>
      <app-configuratie-categorie v-if="currentConfigItem === 'categorie'"></app-configuratie-categorie>
      <app-configuratie-groeperingen v-if="currentConfigItem === 'groeperingen'"></app-configuratie-groeperingen>
      <app-configuratie-shortcuts v-if="currentConfigItem === 'shortcuts'"></app-configuratie-shortcuts>
      <app-configuratie-export v-if="currentConfigItem === 'export'"></app-configuratie-export>
      <app-configuratie-applicatie v-if="currentConfigItem === 'applicatie'"></app-configuratie-applicatie>
      <app-configuratie-about v-if="currentConfigItem === 'about'"></app-configuratie-about>
    </div>
  </div>
</template>

<script>
  import header                   from '../general/header.vue';
  import configuratieleft         from './configuratie-left.vue';
  import configuratierekeningen   from './rekeningen/cfg-rekeningen.vue';
  import configuratiecategorie    from './categorie/cfg-categorie.vue';
  import configuratiegroeperingen from './groeperingen/cfg-groeperingen.vue';
  import configuratieshortcuts    from './shortcuts/cfg-shortcuts.vue';
  import configuratieexport       from './export/cfg-export.vue';
  import configuratieapplicatie   from './applicatie/cfg-applicatie.vue';
  import configuratieabout        from './about/cfg-about.vue';

  export default {
    components: {
      'app-header'                    : header,
      'app-configuratie-left'         : configuratieleft,
      'app-configuratie-rekeningen'   : configuratierekeningen,
      'app-configuratie-categorie'    : configuratiecategorie,
      'app-configuratie-groeperingen' : configuratiegroeperingen,
      'app-configuratie-shortcuts'    : configuratieshortcuts,
      'app-configuratie-export'       : configuratieexport,
      'app-configuratie-applicatie'   : configuratieapplicatie,
      'app-configuratie-about'        : configuratieabout,
    },

    data() {
      return {
      }
    },

    computed: {
      currentConfigItem() {
        return this.$store.getters['nav/currentConfigItem'];
      },
    },
  }
</script>

<style scoped>

.container {
    position: absolute;
    top: 50px;
    left: 150px;
    width: 100%;
    height: 100%;
    flex: 1 0 auto;
    margin: 0;
    text-align: left;
}

</style>
