// Store module Nav //

const stateInit = {
  currentComponent: '',
  currentConfigItem: 'rekeningen',
  boekingSaldiTonen: false,
}

const state = stateInit;

const getters = {
  currentComponent(state) {
    return state.currentComponent;
  },
  currentConfigItem(state) {
    return state.currentConfigItem;
  },
  boekingSaldiTonen(state) {
    return state.boekingSaldiTonen;
  },
}

const mutations = {
  currentComponent(state, componentName) {
    state.currentComponent = componentName;
  },
  currentConfigItem(state, configItem) {
    state.currentConfigItem = configItem;
  },
  toggleSaldiTonen(state) {
        state.boekingSaldiTonen = !state.boekingSaldiTonen;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
