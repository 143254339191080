// Boekingen-regels component //
<template>
  <div class="fin-regel">
    <div class="fin-flex-row"
         v-bind:class="{ 'fin-regel-hover': hover  }"
         @mouseover="hover = true"
         @mouseleave="hover = false"
         >

      <!-- Status tab -->
      <div class="fin-regel-tab"
           v-bind:class="classStatus">
      </div>

      <!-- Spacer -->
      <div class="fin-regel-cel" style="width:2px;">
      </div>

      <!-- Edit knop -->
      <div v-bind:id="boeking.id"
           v-bind:class="classEdit"
           v-on:click="editBoeking"
           >
          <i class="material-icons bk-16"
             v-bind:id="boeking.id"
             >
             edit
          </i>
      </div>

      <!-- Selectie icon -->
      <div v-bind:id="boeking.id"
           v-bind:class="classSelected"
           v-on:click="toggleSelected"
           >
          <i class="material-icons bk-16"
             v-bind:id="boeking.id"
             >
             {{ iconSelected }}
          </i>
      </div>

      <!-- Starred icon -->
      <div v-bind:id="boeking.id"
           v-bind:class="classStarred"
           v-on:click="toggleStarred"
           >
          <i class="material-icons bk-16"
             v-bind:id="boeking.id"
             >
             {{ iconStarred }}
          </i>
      </div>

      <div class="fin-regel-cel" style="width:90px;">
        {{ boeking.datum }}
      </div>

      <div class="fin-regel-cel" style="width:200px;"
           v-bind:class="{ 'fin-accented' : boeking.transactietype === 'Kruispost',
                           'fin-italic' : boeking.transactietype === 'Kruispost'}"
           >
           {{ vanaan }}
      </div>

      <div class="fin-regel-cel" style="width:450px;">
        {{ boeking.omschrijving }}
        <span v-if="boeking.transactietype === 'Voorschot'">
          [
          <span class="fin-accented">
            deel&nbsp;{{ boeking.voorschotpartij }}
          </span>
          ]
        </span>

        <span v-if="boeking.isEigenDeel">
          [
          <span class="fin-accented">
            eigen deel
          </span>
          ]
        </span>

      </div>

      <div class="fin-regel-vseparator"
           v-bind:class="{ 'fin-regel-vseparator-hover': hover }"
           >
      </div>

      <div class="fin-regel-cel" style="width:100px; text-align:right">
        {{ boeking.bedrag_bij | numeriek2decimalen }}
      </div>

      <div class="fin-regel-vseparator"
           v-bind:class="{ 'fin-regel-vseparator-hover': hover }"
           >
      </div>

      <div class="fin-regel-cel" style="width:100px; text-align:right">
        {{ boeking.bedrag_af | numeriek2decimalen }}
      </div>

      <div class="fin-regel-vseparator"
           v-bind:class="{ 'fin-regel-vseparator-hover': hover }"
           >
      </div>

      <!-- Type icons -->
      <div class="fin-regel-icon locked">
          <i class="material-icons bk-16">
             {{ iconType }}
          </i>
      </div>

      <!-- Kenmerken -->
      <div class="fin-regel-cel" style="width:250px;">
        <div class="fin-accented" v-if="boeking.transactietype === 'Kruispost'">
          {{ boeking.kruispostkenmerk }}
        </div>
        <div v-else-if="boeking.transactietype === 'Voorschot'">
            {{ boeking.voorschotomschrijving }}
        </div>
        <div v-else-if="boeking.transactietype === 'Retour voorschot'">
            {{ boeking.voorschotomschrijving }}
        </div>
        <div v-else="boeking.transactietype === 'Regulier'">
          {{ boeking.categorie }}
          <span v-if="boeking.subcategorie !== null">
            <span class="fin-accented">
              &nbsp;/&nbsp;
            </span>
            {{ boeking.subcategorie }}
          </span>
        </div>
      </div>

    </div>

    <div id="divider" class="fin-divider-subbase-3">
    </div>

  </div>
</template>

<script>
export default {
  props: ['boeking'],

  data() {
    return {
      hover: false,
    }
  },

  computed: {
    vanaan () {
      var lstrVanaan = '';
      if (this.boeking.transactietype === 'Kruispost') {
        lstrVanaan = this.boeking.tegenrekeningnaam;
      } else {
         lstrVanaan = this.boeking.vanaan;
      };
      return lstrVanaan
    },
    classStatus () {
      return {
         'complete' : (this.boeking.boekingstatus === 'Complete') && !this.hover,
         'complete-hover' : (this.boeking.boekingstatus === 'Complete') && this.hover,
         'open' : (this.boeking.boekingstatus === 'Open') && !this.hover,
         'open-hover' : (this.boeking.boekingstatus === 'Open') && this.hover
       }
    },
    classEdit () {
      return {
         'fin-regel-icon' : true,
         'invisible' : !this.hover,
         'enabled'   : this.hover
       }
    },
    classStarred () {
      return {
         'fin-regel-icon' : true,
         'invisible' : !this.boeking.isStarred && !this.hover,
         'enabled'   : !this.boeking.isStarred && this.hover,
         'selected' : this.boeking.isStarred && !this.hover,
         'selected-hover' : this.boeking.isStarred && this.hover
       }
    },
    classSelected () {
      return {
         'fin-regel-icon' : true,
         'invisible' : !this.boeking.isSelected && !this.hover,
         'enabled'   : !this.boeking.isSelected && this.hover,
         'selected' : this.boeking.isSelected && !this.hover,
         'selected-hover' : this.boeking.isSelected && this.hover
       }
    },
    iconStarred () {
      if (this.boeking.isStarred) {
          return 'star'
      } else {
        return 'star_border'
      }
    },
    iconSelected () {
      if (this.boeking.isSelected) {
        return 'check_box'
      } else {
        return 'check_box_outline_blank'
      }
    },
    iconType () {
      let result = ''
      switch(this.boeking.transactietype) {
        case 'Standaard':
          if (!this.boeking.isMeetellenVw) {
            result = 'change_history'
          }
          break;
        case 'Kruispost':
          result = 'shuffle'
          break;
        case 'Voorschot':
          result = 'arrow_forward'
          break;
        case 'Retour voorschot':
          result = 'arrow_back'
          break;
        }
      return result
    },
  },

  methods: {
    editBoeking: function() {
      if (event) {
        alert('Edit boeking')
        // this.$store.commit('rekening/setIsEditedId', event.target.id);
        // this.$store.commit('rekening/toggleIsEditschermTonen');
      }
    },
    toggleSelected: function() {
      if (event) {
        this.$store.commit('boeking/toggleSelected', event.target.id);
      }
    },
    toggleStarred: function() {
    if (event) {
        this.$store.dispatch('boeking/toggleStarred', {id: event.target.id});
      }
    },
  },
}
</script>

<style scoped>

.material-icons.bk-16 {
  font-size: 16px;
  padding-top:2px;
}

</style>
