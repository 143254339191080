// configuratie-groeperingen component //
<template>
  <div class="tmp">
    <h1>Groeperingen</h1>
  </div>
</template>

<script>

export default {

  components: {
  },

  computed: {
  },

  data() {
    return {
    }
  }
}
</script>

<style scoped>

/* Tijdelijk */
.tmp {
  width: 100%;
  height: 100%;
  padding: 10px 8px 8px 15px;
  color: var(--text-main);
  background-color: var(--subbase);
}


</style>
