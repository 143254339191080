// Store module categorie //
import Vue   from 'vue';
import axios from 'axios';
const  axiosUrl = '/api/data/v1/categorie';

  // TODO: UpdateIsAktiefChangeCounter vervangen door een meer generieke oplossing //

const stateInit = {
  sortOrder: [0],
  categorien: {
    0 : {
      id: 0,
      naam: 'Default',
      isActive: true,
      isSelected: true,
      subcategorien: {
        0: {
          id: 0,
          naam: 'Default',
          isActive: true
        }
      }
    }
  },
  isInvoerschermTonen: false,
  isAktiefChangeCounter: 0,
  isEditschermTonen: false,
}

const state = stateInit;

const getters = {

  // Voor configuratie //
  categorien(state) {
    let arrCategorien = []
    let blnHasSubcat = false
    state.sortOrder.forEach( (item) => {
      let cat = state.categorien[item]
      if (cat.subcategorien.length == 0) {
        blnHasSubcat = false
      } else {
        blnHasSubcat = true
      }
      let arrCategorie = {
        id: cat.id,
        naam: cat.naam,
        isActive: cat.isActive,
        hasSubcat: blnHasSubcat,
        subcategorien: cat.subcategorien
      }
      arrCategorien.push(arrCategorie);
    });
    return arrCategorien;
  },

  // Voor invoer en edit functies //
  categorienActive(state) {
    let arrCategorien = []
    let blnHasSubcat = false
    state.sortOrder.forEach( (item) => {
      let cat = state.categorien[item]
      if (cat.isActive) {
        if (cat.subcategorien.length == 0) {
          blnHasSubcat = false
        } else {
          blnHasSubcat = true
        }
        let arrCategorie = {
          id: cat.id,
          naam: cat.naam,
          hasSubcat: blnHasSubcat,
        }
        arrCategorien.push(arrCategorie)
      }
    });
    return arrCategorien
  },
  //
  // subcategorienActive(state) {
  //   let arrSubcategorien = []
  //   state.sortOrder.forEach( (id) => {  // sort order eigenlijk niet nodig hier
  //     let cat = state.categorien[id]
  //     if (cat.isActive) {
  //       cat.subcategorien.forEach( (item) => {
  //         if (item.isActive) {
  //           let arrSubcategorie = {
  //             cat_id: cat.id,
  //             id: item.id,
  //             naam: item.naam
  //           }
  //           arrSubcategorien.push(arrSubcategorie);
  //        }
  //       })
  //     }
  //   })
  //   // let arrSubcategorie = {
  //   //   cat_id: 0,
  //   //   id: 0,
  //   //   naam: 'Maak een keuze...'
  //   // }
  //   // arrSubcategorien.push(arrSubcategorie);
  //
  //   return arrSubcategorien
  // },

// nieuwe versie //
  subcategorienActive(state) {
    let arrSubcategorien = []
    state.sortOrder.forEach( (id) => {  // sort order eigenlijk niet nodig hier
      let cat = state.categorien[id]
      if (cat.isSelected) {
        cat.subcategorien.forEach( (item) => {
          if (item.isActive) {
            let arrSubcategorie = {
              cat_id: cat.id,
              id: item.id,
              naam: item.naam
            }
            arrSubcategorien.push(arrSubcategorie);
         }
        })
      }
    })
    return arrSubcategorien
  },


  isAktiefChangeCounter(state) {
    return state.isAktiefChangeCounter;
  },

  isInvoerschermTonen(state) {
    return state.isInvoerschermTonen;
  },

  isEditschermTonen(state) {
    return state.isEditschermTonen;
  },

}


const mutations = {

  // Internal only //

  __categorieDelete(state, id) {
    Vue.delete(state.categorien, id);
    // Remove from the sort order as well
    var index = state.sortOrder.indexOf(id);
    if (index > -1) {
        state.sortOrder.splice(index, 1);
    }
  },

  __categorieToevoegen(state, categorie) {
    // TODO:  Nog geen ordering of ID voor subcat's, alleen weglaten van NULL-regels //
    let arrSubcategorien = []
    if (categorie.subcategorien[0].id != null) {
      categorie.subcategorien.forEach( (subcat) => {
        arrSubcategorien.push(subcat)
      })
    }
    let tmpCategorie = {
      id: categorie.id,
      naam: categorie.naam,
      isActive: categorie.isActive,
      isSelected: false,
      subcategorien: arrSubcategorien
    }
    Vue.set(state.categorien, categorie.id, tmpCategorie);
  },

  __categorieToevoegenAanSortOrder(state, id) {
    state.sortOrder.push(id);
  },

  __toggleIsAktief(state, id) {
    state.categorie[id].isAktief = !state.categorie[id].isAktief;
  },

  __UpdateIsAktiefChangeCounter(state) {
    state.isAktiefChangeCounter += 1;
  },

  // External //

  selectCategorie(state, id) {
    // Altijd ook deselect van de bestaande selected //
    for (const key in state.categorien) {
      if (state.categorien[key].isSelected === true) {
        Vue.set(state.categorien[key], 'isSelected', false)
      }
    }
    Vue.set(state.categorien[id], 'isSelected', true)
  },

  toggleIsInvoerschermTonen(state) {
    state.isInvoerschermTonen = !state.isInvoerschermTonen;
  },

  toggleIsEditschermTonen(state) {
    state.isEditschermTonen = !state.isEditschermTonen;
  },

}


const actions = {

  initCategorien ({commit}, payload) {
    const url = axiosUrl
    axios.get(url)
    .then(response => {
     // TODO: sortorder zelf afleiden uit de data //
      // Fill the sortOrder. Dit gaat er van uit dat de data gesorteerd wordt aangeleverd.
      response.data.forEach( (item) => {
        commit('__categorieToevoegenAanSortOrder', item.id);
      });
      // Create the rekeningen object //
      response.data.forEach( (item) => {
        commit('__categorieToevoegen', item);
      });
      // Remove the default item //
      commit('__categorieDelete', 0);
    })

    .catch(
      error => {
        console.log(error);
      }
    )
  },


  // rekeningDeaktiveren ({commit, rootGetters}, payload) {
  //   const url = axiosUrl + '/deaktiveer/' + payload.id + '/user/' + rootGetters['auth/currentuser']
  //   // var params = new URLSearchParams();
  //   // axios.put(url, params)
  //   axios.put(url)
  //   .then(response => {
  //     // TODO: check response for error text first!!!!!
  //     commit('__toggleIsAktief', payload.id);
  //     commit('__UpdateIsAktiefChangeCounter');
  //   })
  //   .catch(
  //     error => {
  //       console.log(error);
  //     }
  //   )
  // },


  // rekeningAktiveren ({commit, rootGetters}, payload) {
  //   const url = axiosUrl + '/aktiveer/' + payload.id + '/user/' + rootGetters['auth/currentuser']
  //   // var params = new URLSearchParams();
  //   // axios.put(url, params)
  //   axios.put(url)
  //   .then(response => {
  //     // TODO: check response for error text first!!!!!
  //     commit('__toggleIsAktief', payload.id);
  //     commit('__UpdateIsAktiefChangeCounter');
  //   })
  //   .catch(
  //     error => {
  //       console.log(error);
  //     }
  //   )
  // },

}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
