// Store module auth //

import axios from 'axios';
const axiosUrlAuth = '/api/auth';


const state = {
  authEnv: '',
  currentuser: '',
  sessionid: '',
  authenticated: false,
  autologout: false,
  _dbAlias: '',
}

const getters = {
  authEnv(state) {
    return state.authEnv;
  },
  currentuser(state) {
    return state.currentuser;
  },
  sessionid(state) {
    return state.sessionid;
  },
  authenticated(state) {
    return state.authenticated;
  },
  autologout(state) {
    return state.autologout;
  },
  databaseAlias(state) {
    // Check if _dbAlias is initialized
    if (state._dbAlias === '') {
      // If not: try to get a default value from the localStorage
      if (typeof localStorage.databaseAlias ===   'undefined') {
        //console.log('in getter: gebruik default');
        state._dbAlias = 'Default'
      } else {
        //console.log('in getter: gebruik localStorage');
        state._dbAlias = localStorage.databaseAlias;
      }
    };
    return state._dbAlias;
  },
}


const mutations = {
  currentuser(state, userName) {
    state.currentuser = userName;
  },
  sessionid(state, sessionID) {
    state.sessionid = sessionID;
  },
  authenticated(state, authenticated) {
    state.authenticated = authenticated;
  },
  autologout(state, autologout) {
    state.autologout = autologout;
  },
  authEnv(state, authEnv) {
    state.authEnv = authEnv;
  },
  databaseAlias(state, databaseAlias) {
    state._dbAlias = databaseAlias;
  }
}

const actions = {

  loginUser ({commit}, payload) {
    var params = new URLSearchParams();
    params.append('f', 'login');
    params.append('app', this.state.appName);
    params.append('user', payload.userName);
    params.append('pw', payload.password);
    axios.post(axiosUrlAuth, params)
    .then(
      response => {
        if (response.data.succesful) {
          commit('authEnv', response.data.env);
          commit('sessionid', response.data.sessionid);
          commit('currentuser', payload.userName);
          commit('authenticated', true);
          commit('autologout', false);
          commit('databaseAlias', payload.databaseAlias);
          // also update the local storage
          localStorage.sessionid = response.data.sessionid;
          localStorage.currentuser = payload.userName
          localStorage.databaseAlias = payload.databaseAlias;
        } else {
          commit('authEnv', response.data.env);
          commit('autologout', false);
        }
      }
    )
    .catch(
      // dit is bij een technische fout
      error => {
        console.log(error);
      }
    )
  },

  logoutUser ({commit}, payload) {
    var params = new URLSearchParams();
    params.append('f', 'logout');
    params.append('session', this.state.sessionid);
    axios.post(axiosUrlAuth, params)
    .then(response => {
      commit('authEnv', response.data.env);
      commit('sessionid', '');
      commit('currentuser', '');
      commit('authenticated', false);
      commit('autologout', false);
      localStorage.removeItem("currentuser");
      localStorage.removeItem("sessionid");
      // leave the last chosen databaseAlias intact
    })
    .catch(
      // dit is bij een technische fout
      error => {
        console.log(error);
      }
    )
  },

  checkUserSession ({commit}, payload) {
    var params = new URLSearchParams();
    params.append('f', 'checksession');
    params.append('app', this.state.appName);
    params.append('session', this.state.sessionid);
    params.append('user', this.state.currentuser);
    axios.post(axiosUrlAuth, params)
    .then(response => {
      if (response.data.succesful) {
        commit('authEnv', response.data.env);
        commit('authenticated', true);
        // get the databaseAlias from the localStorage
        //commit('databaseAlias', localStorage.databaseAlias);
      } else {
        // The session was no longer valid, so auto logout occurred
        commit('autologout', true);
        // Remove the login and session data in the store (just to be sure)
        commit('authEnv', response.data.env);
        commit('sessionid', '');
        commit('currentuser', '');
        commit('authenticated', false);
        // Remove the login and session data, next time you open the app autologout is false
        localStorage.removeItem("currentuser");
        localStorage.removeItem("sessionid");
      }
    })
    .catch(
      // dit is bij een technische fout
      error => {
        console.log(error);
      }
    )
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
