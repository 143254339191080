// configuratie-left component //
<template>
  <div class="cfg-container">

    <div class="cfg-header">
      Configuratie
    </div>

    <div>
      <ol class="cfg-list">
        <div v-for="option in menuOptions">
          <li v-bind:id="option.id"
              v-bind:class="[currentConfigItem === option.id ? 'cfg-button-active' : 'cfg-button']"
              v-on:click="setConfigItem"
              >
              {{ option.name }}
          </li>
        </div>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
//  props: ['currentConfigItem'],

  data() {
    return {
      menuOptions: [
        {id: 'rekeningen', name: 'Rekeningen' },
        {id: 'categorie', name: 'Categorie' },
        {id: 'groeperingen', name: 'Groeperingen' },
        {id: 'shortcuts', name: 'Shortcuts' },
        {id: 'export', name: 'Export' },
        {id: 'applicatie', name: 'Applicatie' },
        {id: 'about', name: 'About' }
      ],
    }
  },

  computed: {
    currentConfigItem() {
      return this.$store.getters['nav/currentConfigItem'];
    },
  },

  methods: {
    setConfigItem: function(event) {
      if (event) {
          this.$store.commit('nav/currentConfigItem', event.target.id);
      }
    },
  }

}
</script>

<style scoped>


.cfg-container {
  box-sizing: border-box;
  border: none;
  height: 100%;
  color: var(--text-main);
  background-color: var(--overflow);
}

.cfg-header {
  font-size: 20px;
  font-weight: 300px;
  width: 100%;
  height: 25px;
  padding: 8px 0px 0px 0px;
  text-align: center;
}

.cfg-list {
  list-style: none;
  width: 134px;
  padding: 0px;
  margin: 10px 8px 0px 2px;
  border-left: 2px solid var(--overflow);
  background-color: var(--subbase);
}

.cfg-button {
  height: 24px;
  font-size: 16px;
  font-weight: 300px;
  background-color: var(--subbase);
  padding: 5px 0px 0px 4px;
  border-left: 6px solid var(--overflow);
  cursor: pointer;
}

.cfg-button-active {
  height: 24px;
  font-size: 16px;
  font-weight: 300px;
  padding: 5px 0px 0px 4px;
  border-radius: 6px 0px 0px 6px;
  border-left: 6px solid var(--accent-1-full);
  background-color: var(--accent-1-selected);
}

.cfg-button:hover {
  border-radius: 6px 0px 0px 6px;
  border-left: 6px solid var(--accent-1-partial);
  background-color: var(--accent-1-hover);
}

</style>
