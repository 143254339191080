// configuratie-rekening-edit component //
<template>
  <div class="fin-modal"
       v-bind:class="{ 'fin-display'      : rekeningInvoerTonen,
                       'fin-display-none' : !rekeningInvoerTonen }"
    >
    <div class="fin-modal-container">

      <!-- Header -->
      <div class="fin-modal-header fin-flex-row">
        <span  class="fin-width-33-perc fin-flex-row fin-flex-start">
        </span>
        <span class="fin-width-33-perc fin-flex-row fin-flex-center">
          <div class="fin-modal-header-title">
            Rekening invoeren
          </div>
        </span>
        <span class="fin-width-33-perc fin-flex-row fin-flex-end">
          <i class="material-icons fin-close-button" v-on:click="annuleren">close</i>
        </span>
      </div>

      <!-- Body -->
      <div class="fin-modal-body">
        <div class="fin-input-group">
          <label>Rekeningnaam</label>
          <span v-bind:class="{ 'fin-visible' : validateError.naam,
                                'fin-hidden'  : !validateError.naam }"
            >
            <i class="material-icons error-icon">
               error_outline
            </i>
          </span>
          <input v-model="rekening.naam"
                 type="text"
                 v-on:input="validateNaam"
                 >
        </div>
        <div class="fin-input-group">
          <label>Rekeningnummer</label>
          <span v-bind:class="{ 'fin-visible' : validateError.nummer,
                                'fin-hidden'  : !validateError.nummer }"
            >
            <i class="material-icons error-icon">
               error_outline
            </i>
          </span>
          <input v-model="rekening.nummer" type="text">
        </div>
        <div class="fin-input-group">
          <label>Partij</label>
          <span v-bind:class="{ 'fin-visible' : validateError.partij,
                                'fin-hidden'  : !validateError.partij }"
            >
            <i class="material-icons error-icon">
               error_outline
            </i>
          </span>
          <input v-model="rekening.partij" type="text">
        </div>
      </div>

      <!-- Footer -->
      <div class="fin-modal-footer">
        <div class="fin-buttonlist">
          <div v-bind:class="['fin-button', isDataValidated ? 'enabled' : 'disabled']"
               v-on:click="opslaan"
               >
            Opslaan
          </div>
          <div class="fin-button enabled"
               v-on:click="annuleren">
            Annuleren
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {

  computed: {
    rekeningInvoerTonen () {
      return this.$store.getters['rekening/isInvoerschermTonen'];
    },
    rekeningNamen () {
      return this.$store.getters['rekening/rekeningNamen'];
    },
  },

  data() {
    return {
     isDataValidated: false,
     validateError: {
       naam: false,
       nummer: false,  // nog geen checks ingebouwd //
       partij: false   // nog geen checks ingebouwd //
     },
     rekening: {
       naam: '',
       nummer: '',
       partij: ''
     },

    }
  },

  watch: {
    rekeningInvoerTonen (value) {
      if (value) {
        // Het scherm wordt getoond nadat het ontzichtbaar was //
        this.isDataValidated = false;
        this.validateError.naam = false;
        this.validateError.nummer = false;
        this.validateError.partij = false;
        this.rekening.naam = '';
        this.rekening.nummer = '';
        this.rekening.partij = '';
      }
    }
  },

  methods: {
    opslaan: function() {
      if (this.isDataValidated) {
          this.$store.dispatch('rekening/rekeningOpvoeren', {rekening: this.rekening});
      }
    },

    annuleren: function() {
      this.$store.commit('rekening/toggleIsInvoerschermTonen');
    },

    validateNaam: function() {
      if (event) {
        if (this.rekening.naam.length > 0) {
          if ( this.rekeningNamen.includes(this.rekening.naam.toLowerCase()) ) {
            this.isDataValidated = false;
            this.validateError.naam = true;
          } else {
            this.isDataValidated = true;
            this.validateError.naam = false;
          }

        } else {
          this.isDataValidated = false;
        }
      }
    }
  }
}
</script>

<style scoped>

/* TODO: Standaardiseren op een breedte in error.css is ie 20 breed   */
.error-icon {
  width: 30px;
  color: var(--error-icon);
  padding: 6px 6px 2px 6px;
  font-size: 16px;
  font-weight: 200;
  text-align: center;
  cursor: default;
}


</style>
