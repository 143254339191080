// Boekingen-Saldi tonen component //
<template>
  <div id=container class="tmp">
    Hier komen de saldi
  </div>
</template>

<script>
export default {

  data() {
    return {}
  },

}

</script>

<style scoped>

/* Tijdelijk, voor de achtergrond kleur e.d. */
.tmp {
  font-size: 15px;
  font-weight: 300;
  color: var(--text-main);
  background-color: var(--base);
  padding: 0px;
  margin: 0px;
}

</style>
