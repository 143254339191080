// importeren-body component //
<template>
  <div class="fin-body-container">
    <h1>Importeren</h1>
    <div>
      to be developed
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
    }
  },

}
</script>
