// Boekingen-body component //
<template>
  <div class="fin-body-container">
    <app-boekingenheader></app-boekingenheader>
    <div v-for="boeking in boekingen">
        <app-boekingenregels v-bind:boeking="boeking"></app-boekingenregels>
    </div>
  </div>
</template>

<script>
import boekingenheader   from './boekingen-header.vue';
import boekingenregels   from './boekingen-regels.vue';

export default {
  components: {
    'app-boekingenheader'   : boekingenheader,
    'app-boekingenregels'   : boekingenregels,
  },

  computed: {
    boekingen () {
      return this.$store.getters['boeking/boekingen']
    },
  },

  data() {
    return {
    }
  }
}
</script>
