// configuratie-about component //
<template>
  <div class="fin-body-container" id="about">
    <div class="about-regel">
        <div class="about-label">App naam</div>
        <div>{{ appName }}</div>
    </div>
    <div class="about-divider"></div>
    <div class="about-regel">
        <div class="about-label">App versie</div>
        <div>{{ appVersion }}</div>
    </div>
    <div class="about-divider"></div>
    <div class="about-regel">
        <div class="about-label">App omgeving</div>
        <div>{{ appEnv }}</div>
    </div>
    <div class="about-divider"></div>
    <div class="about-regel">
        <div class="about-label">Autorisatie omgeving</div>
        <div>{{ authEnv }}</div>
    </div>
    <div class="about-divider"></div>
    <div class="about-regel">
        <div class="about-label">Service omgeving</div>
        <div>{{ serviceEnv }}</div>
    </div>
    <div class="about-divider"></div>
    <div class="about-regel">
        <div class="about-label">Ingelogde gebruiker</div>
        <div>{{ currentuser }}</div>
    </div>
    <div class="about-divider"></div>
    <div class="about-regel">
        <div class="about-label">Sessie ID</div>
        <div>{{ sessionid }}</div>
    </div>
    <div class="about-divider"></div>
    <div class="about-regel">
        <div class="about-label">Authenticated</div>
        <div> {{ authenticated | booleanToJaNee }}</div>
        <!-- <div v-else>Nee</div> -->
    </div>
    <div class="about-divider"></div>
    <div class="about-regel">
        <div class="about-label">Gebruikte database</div>
        <div>{{ databaseAlias }}</div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

export default {
//  name: 'about',

  computed: {
    ...mapGetters({
      appName       : 'appName',
      appVersion    : 'appVersion',
      appEnv        : 'appEnv'
    }),
    ...mapGetters('boeking', {
      serviceEnv    : 'serviceEnv'
    }),
    ...mapGetters('auth', {
      authEnv        : 'authEnv',
      currentuser    : 'currentuser',
      sessionid      : 'sessionid',
      authenticated  : 'authenticated',
      databaseAlias  : 'databaseAlias'
    })
  }

}
</script>


<style scoped>

.about-regel {
  display: flex;
  flex-flow: row nowrap;
  padding: 5px 5px 5px 5px;
  color: var(--text-main);
  background-color: var(--base);
}

.about-label {
  width: 220px;
  background-color: var(--base);
}

</style>
