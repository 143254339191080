// inzicht-body component //
<template>
  <div class="fin-body-container">
    <h1>Inzicht</h1>
    <div>
      to be developed
    </div>
  </div>
</template>

<script>

export default {

  computed: {
  },

  data() {
    return {}
  },

  methods: {
  },

}

</script>
