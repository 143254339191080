import Vue           from 'vue'
import vueRouter     from 'vue-router'

import login         from '../components/login/login.vue'
import configuratie  from '../components/configuratie/configuratie.vue'
import boekingen     from '../components/boekingen/boekingen.vue'
import importeren    from '../components/importeren/importeren.vue'
import inzicht       from '../components/inzicht/inzicht.vue'
import authGuard     from './auth-guard'

Vue.use(vueRouter)

export const router = new vueRouter({
  routes: [
    {
      path: '/',
      name: 'boekingen',
      component: boekingen,
      beforeEnter: authGuard
    },
    {
      path: '/boekingen',
      name: 'boekingen',
      component: boekingen,
      beforeEnter: authGuard
    },
    {
      path: '/importeren',
      name: 'importeren',
      component: importeren,
      beforeEnter: authGuard
    },
    {
      path: '/inzicht',
      name: 'inzicht',
      component: inzicht,
      beforeEnter: authGuard
    },
    {
      path: '/login',
      name: 'login',
      component: login
    },
    {
      path: '/configuratie',
      name: 'configuratie',
      component: configuratie,
      beforeEnter: authGuard
    }
  ],
  mode: 'history'
})
