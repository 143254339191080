<template>
  <div>
    <app-inzicht-header></app-inzicht-header>
    <app-inzicht-body class="inz-container"></app-inzicht-body>
  </div>
</template>


<script>
  import inzichtheader  from '../general/header.vue';
  import inzichtbody    from './inzicht-body.vue';

  export default {
    components: {
      'app-inzicht-header': inzichtheader,
      'app-inzicht-body'  : inzichtbody,
    },
    data() {
      return {
      }
    }
  }
</script>


<style scoped>

.inz-container {
    position: absolute;
    top: 50px;
}

</style>
