// configuratie-rekening-edit component //
<template>
  <div class="fin-modal"
       v-bind:class="{ 'fin-display'      : this.isEditschermTonen,
                       'fin-display-none' : !this.isEditschermTonen }"
    >
    <div class="fin-modal-container">

      <!-- Header -->
      <div class="fin-modal-header fin-flex-row">
        <span class="fin-width-33-perc fin-flex-row fin-flex-start">
          &nbsp;
        </span>
        <span class="fin-width-33-perc fin-flex-row fin-flex-center">
          <div class="fin-modal-header-title">
            Rekening bewerken
          </div>
        </span>

        <span class="fin-width-33-perc fin-flex-row fin-flex-end">
          <i class="material-icons fin-close-button" v-on:click="annuleren">close</i>
        </span>
      </div>

      <!-- Body -->
      <div class="fin-modal-body">
        <div class="bk-section fin-flex-row fin-flex-start">
          <div class="bk-section-title fin-width-20-perc">
            Algemeen
          </div>

          <div>
          <!-- class="bk-section-body"> -->
            <div class="fin-input-group fin-flex-row">
              <label>
                Rekeningnaam
              </label>
              <span v-bind:class="{ 'fin-visible' : !this.valid.naam,
                                    'fin-hidden'  : this.valid.naam }"
                >
                <span class="fin-tooltip">
                  <i class="material-icons fin-error-icon">
                     error_outline
                  </i>
                  <span class="fin-tooltiptext">{{tooltip.rekeningnaam}}</span>
                </span>
              </span>
              <div v-if="rekening.isAktief">
                <input v-model="rekening.naam"
                       type="text"
                       v-on:input="validateNaam"
                       >
              </div>
              <div v-else>
                <label>
                  {{rekening.naam}}
                </label>
              </div>
            </div>

            <div class="fin-input-group fin-flex-row">
              <label>
                Rekeningnummer
              </label>
              <span v-bind:class="{ 'fin-visible' : !this.valid.nummer,
                                    'fin-hidden'  : this.valid.nummer }"
                >
                <span class="fin-tooltip">
                  <i class="material-icons fin-error-icon">
                     error_outline
                  </i>
                  <span class="fin-tooltiptext">{{tooltip.rekeningnnummer}}</span>
                </span>
              </span>
              <div v-if="rekening.isAktief">
                <input v-model="rekening.nummer"
                       type="text"
                       v-on:input="validateNummer"
                       >
              </div>
              <div v-else>
                <label>
                  {{rekening.nummer}}
                </label>
              </div>
            </div>

            <div class="fin-input-group fin-flex-row">
              <label>
                Partij
              </label>
              <span v-bind:class="{ 'fin-visible' : !this.valid.partij,
                                    'fin-hidden'  : this.valid.partij }"
                >
                <span class="fin-tooltip">
                  <i class="material-icons fin-error-icon">
                     error_outline
                  </i>
                  <span class="fin-tooltiptext">{{tooltip.partij}}</span>
                </span>
              </span>
              <div v-if="rekening.isAktief">
                <input v-model="rekening.partij"
                       type="text"
                       v-on:input="validatePartij"
                       >
              </div>
              <div v-else>
                <label>
                  {{rekening.partij}}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="fin-divider-base">
        </div>

        <div class="bk-section fin-flex-row fin-flex-start">
          <div class="bk-section-title fin-width-20-perc">
            Saldi
          </div>

          <div>
             <!-- class="bk-section-body"> -->
            <div class="fin-input-group fin-flex-row">
              <label>
                Beginsaldo
              </label>
              <span v-bind:class="{ 'fin-visible' : !this.valid.beginsaldo,
                                    'fin-hidden'  : this.valid.beginsaldo }"
                >
                <span class="fin-tooltip">
                  <i class="material-icons fin-error-icon">
                     error_outline
                  </i>
                  <span class="fin-tooltiptext">{{tooltip.beginsaldo}}</span>
                </span>
              </span>
            </span>
              <div v-if="rekening.isAktief">
                <input v-model="rekening.beginsaldo"
                       type="text"
                       v-on:input="validateSaldoEnDatum"
                       >
              </div>
              <div v-else>
                <label>
                  {{rekening.beginsaldo}}
                </label>
              </div>
            </div>

            <div class="fin-input-group fin-flex-row">
              <label>
                Datum beginsaldo
              </label>
              <span v-bind:class="{ 'fin-visible' : !this.valid.saldodatum,
                                    'fin-hidden'  : this.valid.saldodatum }"
                >
                <span class="fin-tooltip">
                  <i class="material-icons fin-error-icon">
                     error_outline
                  </i>
                  <span class="fin-tooltiptext">{{tooltip.saldodatum}}</span>
                </span>
              </span>
              <div v-if="rekening.isAktief">
                <input v-model="rekening.saldodatum"
                       type="text"
                       v-on:input="validateSaldoEnDatum"
                       >
              </div>
              <div v-else>
                <label>
                  {{rekening.saldodatum}}
                </label>
              </div>
            </div>

            <div class="fin-input-group fin-flex-row">
              <label>
                Aantal boekingen
              </label>
              <span class="icon-dummy"></span>
              <label v-if="rekening.aantalboekingen === 0">
                {{rekening.aantalboekingen}}
              </label>
              <span v-else>
                {{rekening.aantalboekingen}}&nbsp;&nbsp;van&nbsp;&nbsp;{{rekening.datumeersteboeking}}&nbsp;&nbsp;t/m&nbsp;&nbsp;{{rekening.datumlaatsteboeking}}
              </span>
            </div>

            <div class="fin-input-group fin-flex-row">
              <label>
                Huidig saldo
              </label>
              <span class="icon-dummy"></span>
              <label>
                {{rekening.huidigsaldo  | numeriek2decimalen }}
              </label>
            </div>
          </div>
        </div>

        <div class="fin-divider-base">
        </div>

        <div class="bk-section fin-flex-row fin-flex-start">
          <div class="bk-section-title fin-width-20-perc">
            Indicatoren
          </div>

          <div>
             <!-- class="bk-section-body"> -->

            <div class="fin-input-group fin-flex-row">
              <label>
                Aktief
              </label>
              <span class="icon-dummy"></span>
              <label>
                {{ rekening.isAktief | booleanToJaNee }}
              </label>
            </div>

            <div class="fin-input-group fin-flex-row">
              <label>
                Tonen in filters
              </label>
              <span class="icon-dummy"></span>
              <label>
                {{ rekening.isInFilter | booleanToJaNee }}
              </label>
            </div>

            <div class="fin-input-group fin-flex-row">
              <label>
                Default selectie in filters
              </label>
              <span class="icon-dummy"></span>
              <label>
                {{ rekening.isDefaultSelectie | booleanToJaNee }}
              </label>
            </div>
          </div>
        </div>

        <div class="fin-divider-base">
        </div>

        <div class="bk-section fin-flex-row fin-flex-start">
          <div class="bk-section-title fin-width-20-perc">
            Opmerkingen
          </div>

          <div>
             <!-- class="bk-section-body"> -->
            <div class="fin-input-group fin-flex-row">
              <label>
                Opmerkingen
              </label>
              <!-- <span class="icon-dummy"></span> -->
              <span v-bind:class="{ 'fin-visible' : !this.valid.opmerking,
                                    'fin-hidden'  : this.valid.opmerking }"
                >
                <span class="fin-tooltip">
                  <i class="material-icons fin-error-icon">
                     error_outline
                  </i>
                  <span class="fin-tooltiptext">{{tooltip.opmerkingen}}</span>
                </span>
              </span>
              <div v-if="rekening.isAktief">
                <input v-model="rekening.opmerking"
                       type="text"
                       style="height: 40px;"
                       v-on:input="validateOpmerking"
                       >
              </div>
              <div v-else>
                <label style="height: 40px;">
                  {{rekening.opmerking}}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="fin-modal-footer">
        <div class="fin-buttonlist">

          <!-- knop : opslaan -->
          <div v-bind:class="['fin-button', isDataValid && isDataChanged && rekening.isAktief ? 'enabled' : 'disabled']"
            v-on:click="opslaan"
            >
            Opslaan
          </div>

          <!-- knop : Annuleren -->
          <div class="fin-button enabled"
               v-on:click="annuleren"
               >
            {{txtKnopAnnuleren}}
          </div>

          <!-- knop : (De-)aktiveren -->
          <div v-if="rekening.isDeAktiveerbaar">
            <div v-if="rekening.isAktief">
              <div class="fin-button enabled"
                      v-on:click="rekeningDeaktiveren"
                      >
                De-aktiveren
              </div>
            </div>
            <div v-else>
              <div class="fin-button enabled"
                      v-on:click="rekeningAktiveren"
                      >
                Aktiveren
              </div>
            </div>
          </div>
          <div v-else>
            <div v-if="rekening.isAktief">
              <div class="fin-button disabled"
                      >
                De-aktiveren
              </div>
            </div>
            <div v-else>
              <div class="fin-button disabled"
                      >
                Aktiveren
              </div>
            </div>
          </div>

          <!-- knop : Verwijderen -->
          <div v-bind:class="['fin-button', rekening.isVerwijderbaar ? 'enabled' : 'disabled']"
            v-on:click="verwijderen"
            >
            Verwijderen
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import isValidDateFromString from '../../../js/valid-date'

export default {

  computed: {
    isEditschermTonen () {
      return this.$store.getters['rekening/isEditschermTonen'];
    },
    isAktiefChangeCounter () {
      return this.$store.getters['rekening/isAktiefChangeCounter'];
    },
    isDataValid () {
      return this.valid.naam &&
             this.valid.nummer &&
             this.valid.partij &&
             this.valid.beginsaldo &&
             this.valid.saldodatum &&
             this.valid.opmerking;
    },
    isDataChanged () {
      return this.rekening.hash !== this.rekening.naam + '@#@' +
                  this.rekening.nummer + '@#@' +
                  this.rekening.partij + '@#@' +
                  this.rekening.beginsaldo + '@#@' +
                  this.rekening.saldodatum + '@#@' +
                  this.rekening.opmerking
    },
    rekeningNamenZonderHuidige () {
      return this.$store.getters['rekening/rekeningNamen'];
       // TODO filter toevoegen //
    },
    txtKnopActiveren () {
      return this.rekening.isAktief ? 'De-aktiveren' : 'Aktiveren'
    },
    txtKnopAnnuleren () {
      return this.isDataChanged ? 'Annuleren' : 'sluiten'
    },

  },

  data() {
    return {
      rekening: {
        id: 0,
        naam: '',
        naamOrigineel: '',
        nummer: '',
        partij: '',
        isAktief: false,
        beginsaldo: '',
        saldodatum: '',
        datumeersteboeking: '',
        datumlaatsteboeking: '',
        aantalboekingen: '',
        huidigsaldo: '',
        isInFilter: false,
        isDefaultSelectie: false,
        opmerking: '',
        hash: '',
        isDeAktiveerbaar: false,
        isVerwijderbaar: false
      },
      valid: {
        naam: true,
        nummer: true,
        partij: true,
        beginsaldo: true,
        saldodatum: true,
        opmerking: true
      },
      tooltip: {
        rekeningnaam: 'De rekeningnaam is verplicht en niet gelijk aan de naam van een andere rekening. De lengte is maximaal 50 tekens',
        rekeningnnummer: 'Het rekeningnummer is verplicht. De lengte is maximaal 50 tekens',
        partij: 'De partij is verplicht. De lengte is maximaal 50 tekens',
        beginsaldo: 'Het beginsaldo is 0 of decimaal(12,2) met een comma',
        saldodatum: 'De datum van het beginsaldo mag leeg zijn als het beginsaldo 0 is. De datum moet op of voor de eerste boeking liggen. Het format is dd-mm-jjjj',
        opmerkingen: 'De opmerking mag leeg zijn. De lengte is maximaal 1000 tekens'
      }
    }
  },

  watch: {
    isEditschermTonen (value) {
      if (value) {
        this.initData();
      }
    },
    isAktiefChangeCounter (value) {
      if (value) {
        this.initData();
      }
    }
  },

  methods: {

    // ---- Knop acties ---- //
    opslaan: function() {
      if (event) {
        if (this.isDataValid && this.isDataChanged && this.rekening.isAktief) {
          if (confirm("Wil je de wijzigingen opslaan?")) {
            let rekening = {
              id : this.rekening.id,
              naam : this.rekening.naam,
              nummer : this.rekening.nummer,
              partij : this.rekening.partij,
              beginsaldo : this.rekening.beginsaldo,
              saldodatum : this.rekening.saldodatum,
              opmerking : this.rekening.opmerking
            }
            this.$store.dispatch('rekening/wijzigingenOpslaan', {rekening: this.rekening});
          }
        }
      }
    },
    annuleren: function() {
      if (event) {
        if (this.isDataChanged) {
          if (confirm("Wil je je wijzigingen kwijt raken?")) {
            this.$store.dispatch('rekening/editAnnuleren');
          }
        } else {
          this.$store.dispatch('rekening/editAnnuleren');
        }
      }
    },
    verwijderen: function() {
      if (event) {
        if (this.rekening.isVerwijderbaar) {
          if (confirm("Wil je de rekening verwijderen?")) {
            this.$store.dispatch('rekening/rekeningVerwijderen', {id: this.rekening.id});
          }
        }
      }
    },
    rekeningDeaktiveren: function() {
      if (event) {
        if (confirm("Wil je de rekening de-aktiveren?")) {
          this.$store.dispatch('rekening/rekeningDeaktiveren', {id: this.rekening.id});
        }
      }
    },
    rekeningAktiveren: function() {
      if (event) {
        if (confirm("Wil je de rekening aktiveren?")) {
          this.$store.dispatch('rekening/rekeningAktiveren', {id: this.rekening.id});
        }
      }
    },

    // ---- (Re)-initialiseren ---- //

    initData: function() {
      let rekeningEdited = this.$store.getters['rekening/rekeningEdited'];
      this.rekening.id = rekeningEdited.id;
      this.rekening.naam = rekeningEdited.naam;
      this.rekening.naamOrigineel = rekeningEdited.naam;
      this.rekening.nummer = rekeningEdited.nummer;
      this.rekening.partij = rekeningEdited.partij;
      this.rekening.isAktief = rekeningEdited.isAktief;
      this.rekening.beginsaldo = rekeningEdited.beginsaldo;
      if (rekeningEdited.saldodatum == null) {
        this.rekening.saldodatum = '';
      } else {
        this.rekening.saldodatum = rekeningEdited.saldodatum;
      }
      this.rekening.datumeersteboeking = rekeningEdited.datumeersteboeking;
      this.rekening.datumlaatsteboeking = rekeningEdited.datumlaatsteboeking;
      this.rekening.aantalboekingen = rekeningEdited.aantalboekingen;
      this.rekening.huidigsaldo = rekeningEdited.huidigsaldo;
      this.rekening.isInFilter = rekeningEdited.isInFilter;
      this.rekening.isDefaultSelectie = rekeningEdited.isDefaultSelectie;
      this.rekening.opmerking = rekeningEdited.opmerking;
      if (rekeningEdited.huidigsaldo == 0) {
        this.rekening.isDeAktiveerbaar = true;
      } else {
        this.rekening.isDeAktiveerbaar =  false;
      };
      if (rekeningEdited.aantalboekingen === 0) {
        this.rekening.isVerwijderbaar = true;
      } else {
        this.rekening.isVerwijderbaar = false;
      };
      this.rekening.hash = this.rekening.naam + '@#@' +
                  this.rekening.nummer + '@#@' +
                  this.rekening.partij + '@#@' +
                  this.rekening.beginsaldo + '@#@' +
                  this.rekening.saldodatum + '@#@' +
                  this.rekening.opmerking;

      // Reset validiteits Indicatoren //
      this.valid.naam = true
      this.valid.nummer = true
      this.valid.partij = true
      this.valid.beginsaldo = true
      this.valid.saldodatum = true
      this.valid.opmerking = true
    },

    // ---- Validaties ---- //

    validateNaam: function() {
      if (event) {
         if (this.rekening.naam.length > 0 && this.rekening.naam.length <= 50) {
          if ( this.rekeningNamenZonderHuidige.includes(this.rekening.naam.toLowerCase()) &&
               this.rekening.naam.toLowerCase() !== this.rekening.naamOrigineel.toLowerCase()) {
            this.valid.naam = false;
          } else {
            this.valid.naam = true;
          }
        } else {
          this.valid.naam = false;
        }
      }
    },
    validateNummer: function() {
      if (event) {
        if (this.rekening.nummer.length > 0 && this.rekening.nummer.length <= 50) {
          this.valid.nummer = true;
        } else {
          this.valid.nummer = false;
        }
      }
    },
    validatePartij: function() {
      if (event) {
        if (this.rekening.partij.length > 0 && this.rekening.partij.length <= 50) {
          this.valid.partij = true;
        } else {
          this.valid.partij = false;
        }
      }
    },
    validateSaldoEnDatum: function() {
        this.validateBeginsaldo()
        this.validateSaldodatum()
    },
    validateBeginsaldo: function() {
      if (event) {
        // const regDecimal = /^-?\d+(\,\d{2})$/;
        const regDecimal = /^0|-?\d+(\,\d{2})$/;
        // if ((this.rekening.beginsaldo.toString().match(regDecimal) || this.rekening.beginsaldo.toString() === '0' ) && this.rekening.beginsaldo.length > 0) {
        if (this.rekening.beginsaldo.toString().match(regDecimal) && this.rekening.beginsaldo.toString() !== '-0,00') {
          this.valid.beginsaldo = true;
        } else {
          this.valid.beginsaldo = false;
        };
      }
    },
    validateSaldodatum: function() {
      if (event) {
        const regNul = /^(0)$|^(0\,00)$/;
        // Check if there is a value for saldodatum (if there is a beginsaldo value other than 0 or 0,00)
        if (this.rekening.beginsaldo.toString().match(regNul)) {
          if (this.rekening.saldodatum.length == 0 || this.rekening.saldodatum == null) {
            // mag leeg zijn, want bedrag = 0(,00) //
            this.valid.saldodatum = true
          } else {
            // Als de datum is gevuld, moet dan moet het een geldige datum zijn
            this.valid.saldodatum = this.__checkDatum()
          }
        } else {
          // Er is een bedrag gevuld, dus moet de datum ook gevuld en geldig zijn
          this.valid.saldodatum = this.__checkDatum()
        }
      }
    },
    __checkDatum: function() {
      if (isValidDateFromString(this.rekening.saldodatum)) {
        if (this.rekening.datumeersteboeking !== 'Nvt') {
          // De eerste datum moet een geldige datum zijn, want hij komt uit de database
          // De saldodatum moet voor de eerste datum liggen (of gelijk zijn)
          if (this.__stringToDate(this.rekening.datumeersteboeking) >= this.__stringToDate(this.rekening.saldodatum)) {
            return true
          } else {
            return false
          }
        } else {
          // Er is nog geen boeking, elke geldige saldodatum voldoet //
          return true
        }
      } else {
        // De datum is niet correct, dus sowieso fout teruggeven //
        return false
      }
    },
    __stringToDate: function(_date) {
                var dateItems=_date.split('-');
                var month=parseInt(dateItems[1]) - 1;
                var formatedDate = new Date(dateItems[2],month,dateItems[0]);
                return formatedDate;
    },
    validateOpmerking: function() {
      if (event) {
        if (this.rekening.opmerking.length <= 1000) {
          this.valid.opmerking = true;
        } else {
          this.valid.opmerking = false;
        }
      }
    },
  }
}
</script>

<style scoped>

.bk-section {
  padding: 8px 8px 0px 8px;
}

.bk-section-title {
  color: var(--text-main-brighter);
  padding: 0px 0px 0px 4px;
}

.icon-dummy {
  width: 20px;
  padding: 6px 4px 2px 4px;
  cursor: default;
}

</style>
