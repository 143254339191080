// Boekingen-invoeren component //
<template>
  <div class="fin-modal"
       v-bind:class="{ 'fin-display' : isInvoerschermTonen ,
                       'fin-display-none' : !isInvoerschermTonen }"
    >
    <div class="fin-modal-container">

      <!-- Header -->
      <div class="fin-modal-header fin-flex-row">
        <span class="fin-width-20-perc fin-flex-row fin-flex-start">
          &nbsp;
        </span>
        <span class="fin-width-59-perc fin-flex-row fin-flex-center">
          <div class="fin-modal-header-title">
            Boeking invoeren voor rekening {{ filterRekening }}
          </div>
        </span>

        <span class="fin-width-20-perc fin-flex-row fin-flex-end">
          <i class="material-icons fin-close-button" v-on:click="annuleren">close</i>
        </span>
      </div>

      <!-- Body -->
      <div class="fin-modal-body">

        <div class="fin-input-group">
          <label>
            Type boeking
          </label>
          <span class="icon-dummy"></span>
          <div class="fin-flex-row">
            <div v-bind:class="['fin-radio-button', boeking.type === 'Standaard' ? 'selected': 'deselected']"
                 id="Standaard"
                 @click="setCurrentTransactieType"
              >
              Standaard
            </div>
            <div v-bind:class="['fin-radio-button', boeking.type === 'Kruispost' ? 'selected': 'deselected']"
                 id="Kruispost"
                 @click="setCurrentTransactieType"
              >
              Kruispost
            </div>
            <div v-bind:class="['fin-radio-button', boeking.type === 'Voorschot' ? 'selected': 'deselected']"
                 id="Voorschot"
                 @click="setCurrentTransactieType"
              >
              Voorschot
            </div>
            <div v-bind:class="['fin-radio-button', boeking.transactieType === 'Retour voorschot' ? 'selected': 'deselected']"
                 id="Retour voorschot"
                 @click="setCurrentTransactieType"
              >
              Retour voorschot
            </div>
          </div>
        </div>

        <div class="fin-input-group">
          <label>
            Datum
          </label>
          <span v-bind:class="[ this.valid.datum ||
                                boeking.datum.length === 0 ||
                                boeking.datum === 'v' ||
                                boeking.datum === 'g' ||
                                boeking.datum === 'e' ? 'fin-hidden' : 'fin-visible' ]">
            <span v-if=""  class="fin-tooltip">
              <i class="material-icons error-icon">
                 error_outline
              </i>
              <span class="fin-tooltiptext">{{tooltip.datum}}</span>
            </span>
          </span>
          <input v-model="boeking.datum" type="text" v-on:blur="expandDate">
        </div>

        <div class="fin-input-group">
          <!-- input (als . dan x anders geen actie -->
          <label>
            van/aan
          </label>
          <span v-bind:class="[ !this.valid.partij && boeking.partij.length !== 0 ? 'fin-visible' : 'fin-hidden' ]">
            <span class="fin-tooltip">
              <i class="material-icons error-icon">
                 error_outline
              </i>
              <span class="fin-tooltiptext">{{tooltip.partij}}</span>
            </span>
          </span>
          <input v-model="boeking.partij" type="text" v-on:input="changePartij">
        </div>

        <div class="fin-input-group">
          <label>
            Omschrijving
          </label>
          <span v-bind:class="[ !this.valid.omschrijving && boeking.omschrijving.length !== 0 ? 'fin-visible' : 'fin-hidden' ]">
            <span class="fin-tooltip">
              <i class="material-icons error-icon">
                error_outline
              </i>
              <span class="fin-tooltiptext">{{tooltip.omschrijving}}</span>
            </span>
          </span>
          <input v-model="boeking.omschrijving" type="text" v-on:input="changeOmschrijving">
        </div>

        <!-- bedrag -->
        <div class="fin-input-group">
          <label>
            Bedrag
          </label>
          <span v-bind:class="[ !this.valid.bedrag && boeking.bedrag.length !== 0 ? 'fin-visible' : 'fin-hidden' ]">
            <span class="fin-tooltip">
              <i class="material-icons error-icon">
                error_outline
              </i>
              <span class="fin-tooltiptext">{{tooltip.bedrag}}</span>
            </span>
          </span>
          <input v-model="boeking.bedrag" type="text" v-on:input="changeBedrag" v-on:blur="exitBedrag">
        </div>

        <!-- Velden alleen by Standaard -->
        <div v-bind:class="[boeking.type === 'Standaard' ? 'fin-display' : 'fin-display-none']">

           <!-- dropdown categorie-->
          <div class="fin-input-group">
            <label>
              Categorie
            </label>
            <span v-bind:class="[ !this.valid.categorie && boeking.cat_id === 0 ? 'fin-visible' : 'fin-hidden' ]">
              <span class="fin-tooltip">
                <i class="material-icons error-icon">
                  error_outline
                </i>
                <span class="fin-tooltiptext">{{tooltip.categorie}}</span>
              </span>
            </span>
            <select v-model="boeking.cat_id" v-on:change="changeCategorie">
              <option v-for="categorie in categorien" v-bind:value="categorie.id">
                {{ categorie.naam }}
              </option>
            </select>
          </div>

          <!-- dropdown  subcategorie-->
          <div class="fin-input-group">
            <label>
              Subcategorie
            </label>
            <span v-bind:class="[ !this.valid.subcategorie && boeking.scat_id === 0 && boeking.hasSubcat ? 'fin-visible' : 'fin-hidden' ]">
              <span class="fin-tooltip">
                <i class="material-icons error-icon">
                   error_outline
                </i>
                <span class="fin-tooltiptext">{{tooltip.subcategorie}}</span>
              </span>
            </span>
            <span v-if="boeking.hasSubcat">
              <select v-model="boeking.scat_id" v-on:change="changeSubcategorie">
                <option v-for="subcategorie in subcategorien" v-bind:value="subcategorie.id">
                  {{ subcategorie.naam }}
                </option>
              </select>
            </span>
            <span v-else>
              Nvt
            </span>
          </div>

          <!-- Boeking meetellen -->
          <div class="fin-input-group">
            <label>
              Boeking meetellen V&W
            </label>
            <span class="icon-dummy"></span>
            <input type="checkbox" v-model="boeking.isMeetellenVw">
          </div>
        </div>

<!-- TODO: bindingen verder uitwerken per soort -->

        <!-- Velden alleen by Kruispost -->
        <div v-bind:class="[boeking.type === 'Kruispost' ? 'fin-display' : 'fin-display-none']">

          <div class="fin-input-group">
           <label>Tegenrekening</label>
           <input label="tegenrekeningnaam" type="text">
          </div>
          <div class="fin-input-group">
           <label>Keuzelijst kruisposten</label>
           <input label="kp_lijst" type="text">
          </div>
        </div>

        <!-- Velden alleen by Voorschot -->
        <div v-bind:class="[boeking.type === 'Voorschot' ? 'fin-display' : 'fin-display-none']">
          <div class="fin-input-group">
            <label>Voor</label>
            <input label="voorschotpartij" type="text">
          </div>
          <div class="fin-input-group">
            <!-- TODO: keuzelijst openstaande voorschotten -->
            <label>Voorschot omschrijving</label>
            <input label="voorschotomschrijving" type="text">
          </div>
        </div>

        <!-- Velden alleen by Retour voorschot -->
        <div v-bind:class="[boeking.type === 'Retour voorschot' ? 'fin-display' : 'fin-display-none']">
            <!-- TODO: keuzelijst openstaande voorschotten -->
          <div class="fin-input-group">
            <label>Van</label>
            <input label="voorschotpartij" type="text">
          </div>
          <div class="fin-input-group">
            <label>Voorschot omschrijving</label>
            <input label="voorschotomschrijving" type="text">
          </div>
        </div>

      </div> <!-- end body -->

    <div class="fin-modal-footer">
      <div class="fin-buttonlist">

        <div v-bind:class="['fin-button', isDataValid ? 'enabled' : 'disabled' ]"
          @click="invoerOpslaan"
          >
          <span v-bind:class="[ 'fin-tooltip', isDataValid ? 'disabled' : '']">
            Opslaan
            <span v-bind:class="[ 'fin-tooltiptext', isDataValid ? 'disabled' : '']">{{tooltip.opslaanButton}}</span>
          </span>
        </div>

        <div class="fin-button enabled"
             @click="annuleren"
             >
           Annuleren
         </div>
       </div>
     </div>

  </div>

</template>


<script>

export default {

  data() {
    return {
      boeking: {
        rek_id: 0,
        datum: '',
        partij: '',
        omschrijving: '',
        bedrag: '',
        cat_id: 0,
        hasSubcat: false,
        scat_id: 0,
        type: 'Standaard',
        status: 'Open',
        isMeetellenVw: true,
        tegenrekening_id: 0,
        kp_id: 0,
        voorschotpartij_id: 0,
        voorschotomschrijving: ''
        // En nog meer velden voor andere typen
      },
      valid: {
        datum: false, // validatie nog 'echt' maken
        partij: false,
        omschrijving: false,
        bedrag: false,
        categorie: false,
        subcategorie: true,
        isMeetellenVw: true,  //allways true
        tegenrekening_id: false,
        kp_id: true,
        voorschotpartij_id: false,
        voorschotomschrijving: false
      },
      // changed: {
      //   datum: false,
      //   partij: false,
      //   omschrijving: false,
      //   bedrag: false,
      //   categorie: false,
      //   subcategorie: false,
      //   isMeetellenVw: true,  //allways true
      //   tegenrekening_id: false,
      //   kp_id: true,
      //   voorschotpartij_id: false,
      //   voorschotomschrijving: false
      // },
      tooltip: {
        datum: 'De datum moet geldig zijn',
        partij: 'De maximale lengte van de partij is 250 tekens',
        omschrijving: 'De maximale lengte van de omschrijving is 500 tekens',
        bedrag: 'Het bedrag mag alleen óf hele getallen bevatten, óf twee decimalen hebben', // komma vs punt?
        categorie: 'De categorie moet gekozen worden',
        subcategorie: 'Bij de gekozen categorie moet ook een subcategorie gekozen worden',
        isMeetellenVw: 'Nvt',
        opslaanButton: 'Alle zichtbare velden moeten ingevuld zijn, tenzij anders aangegeven'
      },
    }
  },

  computed: {
    isInvoerschermTonen () {
      return this.$store.getters['boeking/isInvoerschermTonen']
    },
    filterRekening () {
      return this.$store.getters['boeking/filterRekening']
    },
    filterRekeningId () {
      return this.$store.getters['boeking/filterRekeningId']
    },
    categorien () {
      return this.$store.getters['categorie/categorienActive']
    },
    subcategorien () {
      return this.$store.getters['categorie/subcategorienActive']
    },
    isDataValidStandaard () {
      return this.valid.datum &&
             this.valid.partij &&
             this.valid.omschrijving &&
             this.valid.bedrag &&
             this.valid.categorie &&
             this.valid.subcategorie &&
             this.valid.isMeetellenVw;
    },
    isDataValidKruispost () {
      return false
      // return this.valid.datum &&
      //        this.valid.partij &&
      //        this.valid.omschrijving &&
      //        this.valid.bedrag &&
      //        this.valid.tegenrekening_id &&
      //        this.valid.kp_id
    },
    isDataValidVoorschot () {
      return false
      // return this.valid.datum &&
      //        this.valid.partij &&
      //        this.valid.omschrijving &&
      //        this.valid.bedrag &&
      //        this.valid.voorschotpartij_id &&
      //        this.valid.voorschotomschrijving
    },
    isDataValidRetourVoorschot () {
      return false
    },
    isDataValid () {
      let resultaat = ''
      switch(this.boeking.type) {
        case 'Standaard':
          resultaat = this.isDataValidStandaard
          break;
        case 'Kruispost':
          resultaat = this.isDataValidKruispost
          break;
        case 'Voorschot':
          resultaat = this.isDataValidVoorschot
          break;
        case 'Retour voorschot':
          resultaat = this.isDataValidRetourVoorschot
          break;
      }
      return resultaat
    }
  },

  methods: {
    invoerOpslaan: function() {
      if (this.isDataValidated) {
        this.$store.commit('boeking/isInvoerschermTonen', false)
      }
    },
    opslaan: function() {
      if (event) {
        if (this.isDataValid) {
          if (confirm("Wil je de wijzigingen opslaan?")) {
            // let tmpBoeking = {
            //   id : this.boeking.id,
            //   naam : this.boeking.naam,
            //   nummer : this.boeking.nummer,
            //   partij : this.boeking.partij,
            //   beginsaldo : this.boeking.beginsaldo,
            //   saldodatum : this.boeking.saldodatum,
            //   opmerking : this.boeking.opmerking
            // }
            this.$store.dispatch('boeking/opslaan', this.boeking);
          }
        }
      }
    },

    annuleren: function() {
      this.$store.commit('boeking/isInvoerschermTonen', false)
    },

    changePartij: function() {
      console.log(this.boeking.partij)
      if (this.boeking.partij.length > 0 && this.boeking.partij.length <= 250) {
        this.valid.partij = true
      } else {
        this.valid.partij = false
      }
    },

    changeOmschrijving: function() {
      console.log(this.boeking.omschrijving);
      if (this.boeking.omschrijving.length > 0 && this.boeking.omschrijving.length <= 500) {
        this.valid.omschrijving = true
      } else {
        this.valid.omschrijving = false
      }
    },

    changeBedrag: function() {
      const regWholeOrDecimalNotZero = /^-?0((\,|\.)(([0][1-9])|([1-9][0-9])))$|^-?[1-9]\d*((\,|\.)\d{2})$|^-?[1-9]\d*$/
      if (this.boeking.bedrag.toString().match(regWholeOrDecimalNotZero)) {
        this.valid.bedrag = true
      } else {
        this.valid.bedrag = false
      }
    },

    exitBedrag: function() {
      // voeg twee nullen toe indien een geheel getal
      const regWholeNumber = /^-?[1-9]\d*$/
      if (this.boeking.bedrag.toString().match(regWholeNumber)) {
        this.boeking.bedrag = this.boeking.bedrag + ',00'
      }
      // vervang een eventuele punt door een komma
      this.boeking.bedrag = this.boeking.bedrag.replace('.', ',')
    },

    changeCategorie: function() {
      this.$store.commit('categorie/selectCategorie', this.boeking.cat_id)

      // reset eerst de (validiteit van de) subcategorie //
      this.boeking.scat_id = 0
      this.valid.subcategorie = false

      // Controleer
      this.categorien.forEach( (item) => {
        if (item.id === this.boeking.cat_id) {
          this.boeking.hasSubcat = item.hasSubcat
          if (!item.hasSubcat) {
            // er is geen subcat nodig dus valid is toch true //
            this.valid.subcategorie = true
          }
        }
      })
      this.valid.categorie = true
    },

    changeSubcategorie: function() {
      this.valid.subcategorie = true
    },

    setCurrentTransactieType: function(event) {
      if (event) {
        this.boeking.type = event.target.id
      }
    },

    expandDate: function(event) {
      if (event) {
        let resultaat = this.boeking.datum;
        const ldtVandaag = new Date();
        const regValidDate = /(^(((0?[1-9]|1[0-9]|2[0-8])-(0?[1-9]|1[012]))|((29|30|31)-(0?[13578]|1[02]))|((29|30)-(0?[469]|11)))-(19|[2-9][0-9])\d\d$)|(^29-0?2-(19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)/;
        const regDag = /^(0?[1-9]|[12][0-9]|3[01])$/;
        const regMaand = /^(0?[1-9]|[12][0-9]|3[01])$/;
        const regDatum = /^(0?[1-9]|[12][0-9]|3[01])$/;

        switch(this.boeking.datum.toLowerCase()) {
          case 'v':
            resultaat = ldtVandaag.getDate() +  '-' + (ldtVandaag.getMonth()+1) +  '-' + ldtVandaag.getFullYear();
            break;
          case 'g':
            let ldtGisteren = new Date();
            ldtGisteren.setDate(ldtGisteren.getDate()-1);
            resultaat = ldtGisteren.getDate() +  '-' + (ldtGisteren.getMonth()+1) +  '-' + ldtGisteren.getFullYear();
            break;
          case 'e':
            let ldtEergisteren = new Date();
            ldtEergisteren.setDate(ldtEergisteren.getDate()-2);
            resultaat = ldtEergisteren.getDate() +  '-' + (ldtEergisteren.getMonth()+1) +  '-' + ldtEergisteren.getFullYear();
            break;
          default:
            if (this.boeking.datum.match(regDag)) {
              let dagnummer = this.boeking.datum;
              if (Number(dagnummer) <= ldtVandaag.getDate()) {
                resultaat = dagnummer + '-' + String(ldtVandaag.getMonth()+1) + '-' + String(ldtVandaag.getFullYear());
              } else {
                if (ldtVandaag.getMonth()===0) {
                  resultaat = dagnummer + '-12-' + String(ldtVandaag.getFullYear()-1);
                } else {
                  resultaat = dagnummer + '-' + String(ldtVandaag.getMonth()) + '-' + String(ldtVandaag.getFullYear());
                }
              }
            }
        }

        // Return the date to the v-model
        this.boeking.datum = resultaat;

        // Validate the date //
        if (this.boeking.datum.match(regValidDate)) {
          this.valid.datum = true
        } else {
          this.valid.datum = false
        }

      }
    }
  }
}
</script>

<style scoped>

/* nog opnemen in main.css */
.icon-dummy {
  width: 20px;
  padding: 6px 4px 2px 4px;
  cursor: default;
}

/* nog opnemen in main.css */
.error-icon {
  width: 20px;
  color: var(--error-icon);
  padding: 6px 4px 2px 4px;
  font-size: 16px;
  font-weight: 200;
  text-align: center;
  cursor: default;
}


</style>
