<template>
  <div>
    <app-importeren-header></app-importeren-header>
    <app-importeren-body class="imp-container"></app-importeren-body>
  </div>
</template>


<script>
  import importerenheader  from '../general/header.vue';
  import importerenbody    from './importeren-body.vue';

  export default {
    components: {
      'app-importeren-header': importerenheader,
      'app-importeren-body'  : importerenbody,
    },
    data() {
      return {
      }
    }
  }
</script>

<style scoped>

.imp-container {
    position: absolute;
    top: 50px;
}

</style>
