// Boekingen-left component //
<template>
  <div class="fin-filter-container">

    <div class="fin-filterheader">
      Filters
    </div>

    <!-- Filter Rekeningen -->
    <div class="fin-filter">
      <div class="fin-filterlistheader fin-flex-row">
        <div class="fin-filterlistheader-margin">
        </div>
        <div class="fin-filterlistheader-title">
          Rekeningen
        </div>
        <div class="fin-filterlistheader-icon fin-flex-end fin-cursor-pointer ">
          <div v-if="rekeningenIsExpanded"
               v-on:click="filterToggleExpandRekeningen"
               >
               <i class="material-icons">expand_less</i>
          </div>
          <div v-else
               v-on:click="filterToggleExpandRekeningen"
               >
               <i class="material-icons">expand_more</i>
          </div>
        </div>
      </div>
      <div class="fin-divider-surface">
      </div>
      <div class="fin-filterlistbody"
           v-bind:class="{ 'fin-display'      : rekeningenIsExpanded,
                           'fin-display-none' : !rekeningenIsExpanded }"
        >
        <ol class="fin-filterlist">
          <div v-for="rekening in rekeningen">
              <li v-if="rekening.isInFilter"
                  class="fin-filterregel"
                  v-bind:id="rekening.id+'$'+rekening.naam"
                  v-bind:class="{ 'fin-filterregel-deselected' : rekening.naam !== filterRekening,
                                  'fin-filterregel-selected'   : rekening.naam === filterRekening }"
                  v-on:click="filterSelectRekening"
                  >
                {{ rekening.naam }}
              </li>
          </div>
        </ol>
      </div>
    </div>

    <!-- Filter Perioden -->
    <div class="fin-filter">
      <div class="fin-filterlistheader fin-flex-row">
        <div class="fin-filterlistheader-margin">
        </div>
        <div class="fin-filterlistheader-title">
          Perioden
        </div>
        <div class="fin-filterlistheader-icon fin-flex-end fin-cursor-pointer ">
          <div v-if="periodenIsExpanded"
               v-on:click="filterToggleExpandPerioden"
               >
               <i class="material-icons">expand_less</i>
          </div>
          <div v-else
               v-on:click="filterToggleExpandPerioden"
               >
               <i class="material-icons">expand_more</i>
          </div>
        </div>
      </div>
      <div class="fin-divider-surface">
      </div>
      <div class="fin-filterlistbody"
           v-bind:class="{ 'fin-display'      : periodenIsExpanded,
                           'fin-display-none' : !periodenIsExpanded }"
        >
        <ol class="fin-filterlist">
          <div v-for="item in maandenVoorFilter">
            <div class="fin-jaartal-header fin-flex-row"
                v-bind:class="{ 'fin-jaartal-selected' : item.isJaarSelected,
                                'fin-jaartal-partial-selected': item.isJaarPartialSelected
                  }"
                >
              <div class="fin-jaartal-header-jaar">
                <div v-if="item.isJaarSelected"
                     v-on:click="jaarDeselect(item.jaartal)"
                     >
                     {{item.jaartal}}
                </div>
                <div v-else
                     v-on:click="jaarSelect(item.jaartal)"
                     >
                     {{item.jaartal}}
                </div>
              </div>

              <div class="fin-filterlistheader-icon">
                <div v-if="item.isExpanded"
                     v-on:click="jaarCollapse(item.jaartal)"
                     >
                     <i class="material-icons">expand_less</i>
                </div>
                <div v-else
                     v-on:click="jaarExpand(item.jaartal)"
                     >
                     <i class="material-icons">expand_more</i>
                </div>
              </div>
            </div>

            <div class="fin-divider-base">
            </div>

            <div v-bind:id="'maandenIn'+item.jaartal"
                 v-bind:class="{ 'fin-display-none' : !item.isExpanded }"
                 >
              <div v-for="el in item.maanden">
                <li class="fin-filterregel"
                    v-bind:id="el.jaarmaand"
                    v-bind:class="{ 'fin-filterregel-deselected' : !el.isSelected,
                                    'fin-filterregel-selected'   : el.isSelected }"
                    v-on:click="maandChangeSelected"
                    >
                  {{ el.maandnaam }}
                </li>
              </div>
            </div>

          </div>
        </ol>
      </div>
    </div>

    <!-- Filter Selecties -->
    <div class="fin-filter">
      <div class="fin-filterlistheader fin-flex-row">
        <div class="fin-filterlistheader-margin">
        </div>
        <div class="fin-filterlistheader-title">
          Selecties
        </div>
        <div class="fin-filterlistheader-icon fin-flex-end fin-cursor-pointer ">
          <div v-if="selectiesIsExpanded"
               v-on:click="filterToggleExpandSelecties"
               >
               <i class="material-icons">expand_less</i>
          </div>
          <div v-else
               v-on:click="filterToggleExpandSelecties"
               >
               <i class="material-icons">expand_more</i>
          </div>
        </div>
      </div>
      <div class="fin-divider-surface">
      </div>
      <div class="fin-filterlistbody"
           v-bind:class="{ 'fin-display'      : selectiesIsExpanded,
                           'fin-display-none' : !selectiesIsExpanded }"
        >
        <ol class="fin-filterlist">
          <li class="fin-filterregel"
              v-bind:class="{ 'fin-filterregel-deselected' : !filterSelectedOnly,
                              'fin-filterregel-selected'   : filterSelectedOnly }"
              v-on:click="toggleFilterSelectedOnly"
              >
            Alleen vinkjes
          </li>
          <li class="fin-filterregel"
              v-bind:class="{ 'fin-filterregel-deselected' : !filterStarredOnly,
                              'fin-filterregel-selected'   : filterStarredOnly }"
              v-on:click="toggleFilterStarredOnly"
              >
            Alleen sterren
          </li>
        </ol>
      </div>
    </div>

    <!-- Filter Status -->
    <div class="fin-filter">
      <div class="fin-filterlistheader fin-flex-row">
        <div class="fin-filterlistheader-margin">
        </div>
        <div class="fin-filterlistheader-title">
          Status
        </div>
        <div class="fin-filterlistheader-icon fin-flex-end fin-cursor-pointer ">
          <div v-if="statusIsExpanded"
               v-on:click="filterToggleExpandStatus"
               >
               <i class="material-icons">expand_less</i>
          </div>
          <div v-else
               v-on:click="filterToggleExpandStatus"
               >
               <i class="material-icons">expand_more</i>
          </div>
        </div>
      </div>
      <div class="fin-divider-surface">
      </div>
      <div class="fin-filterlistbody"
           v-bind:class="{ 'fin-display'      : statusIsExpanded,
                           'fin-display-none' : !statusIsExpanded }"
        >
        <ol class="fin-filterlist">
          <div v-for="status in boekingStatussen">
              <li class="fin-filterregel"
                  v-bind:id="status.naam"
                  v-bind:class="{ 'fin-filterregel-deselected' : !status.isSelected,
                                  'fin-filterregel-selected'   : status.isSelected }"
                  v-on:click="toggleFilterStatus"
                  >
                {{ status.naam }}
              </li>
          </div>
        </ol>
      </div>
    </div>

    <!-- Filter Type -->
    <div class="fin-filter">
      <div class="fin-filterlistheader fin-flex-row">
        <div class="fin-filterlistheader-margin">
        </div>
        <div class="fin-filterlistheader-title">
          Type
        </div>
        <div class="fin-filterlistheader-icon fin-flex-end fin-cursor-pointer">
          <div v-if="typeIsExpanded"
               v-on:click="filterToggleExpandType"
               >
               <i class="material-icons">expand_less</i>
          </div>
          <div v-else
               v-on:click="filterToggleExpandType"
               >
               <i class="material-icons">expand_more</i>
          </div>
        </div>
      </div>
      <div class="fin-divider-surface">
      </div>
      <div class="fin-filterlistbody"
           v-bind:class="{ 'fin-display'      : typeIsExpanded,
                           'fin-display-none' : !typeIsExpanded }"
        >
        <ol class="fin-filterlist">
          <div v-for="type in transactieTypes">
              <li class="fin-filterregel"
                  v-bind:id="type.naam"
                  v-bind:class="{ 'fin-filterregel-deselected' : !type.isSelected,
                                  'fin-filterregel-selected'   : type.isSelected }"
                  v-on:click="toggleFilterType"
                  >
                {{ type.naam }}
              </li>
          </div>
        </ol>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters }    from 'vuex';

export default {

  data() {
    return {
      rekeningenIsExpanded: true,
      periodenIsExpanded: true,
      selectiesIsExpanded: false,
      statusIsExpanded: false,
      typeIsExpanded: false
    }
  },

  computed: {
    ...mapGetters('rekening', {
      rekeningen                 : 'rekeningen',
    }),
    ...mapGetters('boeking', {
      filterRekening             : 'filterRekening',
      maandenVoorFilter          : 'maandenVoorFilter',
      filterSelectedOnly         : 'filterSelectedOnly',
      filterStarredOnly          : 'filterStarredOnly',
      boekingStatussen           : 'boekingStatussen',
      transactieTypes            : 'transactieTypes',
    }),
  },

  methods: {
    filterSelectRekening: function(event) {
      if (event) {
        this.$store.commit('boeking/setFilterRekening', event.target.id);
        }
    },
    maandChangeSelected: function(event) {
      if (event) {
        if (event.target.classList.contains('fin-filterregel-selected')) {
        // At the moment it is selected, so clicking it will deselect
  	      this.$store.commit('boeking/filterMaandDeselect', event.target.id);
        } else {
  	      // At the moment it is not selected, so clicking it will add this jaarmaand to the selection
  	      this.$store.commit('boeking/filterMaandSelect', event.target.id);
        }
      }
    },
    jaarSelect: function(jaartal) {
      this.$store.commit('boeking/filterJaarSelect', jaartal);
    },
    jaarDeselect: function(jaartal) {
      this.$store.commit('boeking/filterJaarDeselect', jaartal);
    },
    jaarExpand: function(jaartal) {
      this.$store.commit('boeking/filterJaarExpand', jaartal);
    },
    jaarCollapse: function(jaartal) {
      this.$store.commit('boeking/filterJaarCollapse', jaartal);
    },
    toggleFilterStatus: function(event) {
      if (event) {
        this.$store.commit('boeking/filterToggleStatus', event.target.id);
        }
    },
    toggleFilterType: function(event) {
      if (event) {
        this.$store.commit('boeking/filterToggleType', event.target.id);
        }
    },
    toggleFilterSelectedOnly: function() {
      this.$store.commit('boeking/toggleFilterSelectedOnly');
    },
    toggleFilterStarredOnly: function() {
      this.$store.commit('boeking/toggleFilterStarredOnly');
    },

    // Filter expands and collapse
    filterToggleExpandRekeningen: function() {
      this.rekeningenIsExpanded = !this.rekeningenIsExpanded
    },
    filterToggleExpandPerioden: function() {
      this.periodenIsExpanded = !this.periodenIsExpanded
    },
    filterToggleExpandSelecties: function() {
      this.selectiesIsExpanded = !this.selectiesIsExpanded
    },
    filterToggleExpandStatus: function() {
      this.statusIsExpanded = !this.statusIsExpanded
    },
    filterToggleExpandType: function() {
      this.typeIsExpanded = !this.typeIsExpanded
    }
  }

}
</script>
