// Boekingen-header component //
<template>
  <div class="bk-header-container">
    <div class="bk-kop">
      <span class="fin-flex-row fin-width-33-perc fin-flex-start">
        <div v-bind:class="['fin-button', !isInvoerschermTonen ? 'enabled' : 'disabled']"
             v-on:click="openBoekingInvoer"
             >
          <i class="material-icons">add</i>
        </div>

        <div v-bind:class="['fin-button', isFinaliserenEnabled ? 'enabled' : 'disabled']"
             v-on:click="boekingenFinaliseren"
             >
          <i class="material-icons">done</i>
        </div>
      </span>

      <span class="fin-flex-row fin-width-33-perc fin-flex-center">
        <span class="bk-kop-titel">
          {{ filterRekening }}
        </span>
      </span>

      <span class="fin-flex-row fin-width-33-perc fin-flex-end">
        <div class="bk-kop-subtitel">
          Saldo&nbsp;{{ saldo }}
        </div>

        <div class="fin-filterlistheader-icon fin-cursor-pointer"
             v-on:click="toggleSaldiTonen"
             >
          <div v-if="boekingSaldiTonen">
            <i class="material-icons">expand_less</i>
          </div>
          <div v-else>
            <i class="material-icons">expand_more</i>
          </div>
        </div>
      </span>

    </div>

    <app-boekingensaldi  v-bind:class="{ 'fin-display': boekingSaldiTonen,
                                         'fin-display-none' : !boekingSaldiTonen }"
                                         >
    </app-boekingensaldi>

    <div class="bk-kolomkoppen fin-flex-row">
      <div class="grid--cell" style="width:50px;">&nbsp</div>
      <div class="grid--cell" style="width:90px;">Datum</div>
      <div class="grid--cell" style="width:200px;">Van/aan</div>
      <div class="grid--cell" style="width:453px;">Omschrijving</div>
      <div class="grid--cell" style="width:103px;">Bij</div>
      <div class="grid--cell" style="width:123px;">Af</div>
      <div class="grid--cell" style="width:250px;">Kenmerken</div>
    </div>

    <div class="fin-divider fbg-text-main-darker fh1">
    </div>
    <div class="fin-divider fh4">
    </div>

  </div>
</template>

<script>
import boekingensaldi    from './boekingen-saldi.vue';

export default {

  components: {
    'app-boekingensaldi'    : boekingensaldi,
  },

  data() {
    return {
      saldo: '0,00',
      isFinaliserenEnabled: false
    }
  },


  computed: {
    isInvoerschermTonen () {
      return this.$store.getters['boeking/isInvoerschermTonen'];
    },
    boekingSaldiTonen () {
      return this.$store.getters['nav/boekingSaldiTonen'];
    },
    filterRekening () {
      return this.$store.getters['boeking/filterRekening'];
    }
  },


  methods: {
    toggleSaldiTonen: function() {
      this.$store.commit('nav/toggleSaldiTonen');
    },
    openBoekingInvoer: function() {
      this.$store.commit('boeking/isInvoerschermTonen', true);
    },
    boekingenFinaliseren: function() {
      if (this.isFinaliserenEnabled) {
        alert('Finaliseren');
      }
    }
  }
}
</script>

<style scoped>

.bk-header-container {
  margin: 0;
  padding: 0px 0px 0px 5px;
}

.bk-kop {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  color: var(--text-main);
  margin: 0px 0px 10px 0px;
}

.bk-kop-titel {
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.bk-kop-subtitel {
  font-size: 18px;
  margin: 0;
  padding: 0;
}

.bk-kolomkoppen {
  margin-bottom: 4px;
  font-size: 15px;
  font-weight: 300;
}

.grid--cell {
   color: var(--text-main-darker); /* blijbaar nog wel nodig */
   padding: 2px;
}

</style>
