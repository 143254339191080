<template>
  <main>
    <router-view></router-view>
  </main>
</template>


<script>
export default {
  data() {
    return {
    }
  },

  created() {
    this.$store.dispatch('rekening/initRekeningen')
    this.$store.dispatch('boeking/initFilterMaanden')
    this.$store.dispatch('boeking/initBoekingen')
    this.$store.dispatch('categorie/initCategorien')
  },

}
</script>
