// Header component //
<template>
  <div class="hd-header">
    <div class="hd-kop-links">
        <div class="hd-button hd-button-wide"
             v-bind:class="{ 'hd-button_active': currentComponent == 'Boekingen' }"
             v-on:click="showBoekingen"
             >
             Boekingen
        </div>
        <div class="hd-button hd-button-wide"
             v-bind:class="{ 'hd-button_active': currentComponent == 'Importeren' }"
             v-on:click="showImporteren"
             >
             Importeren
           </div>
        <div class="hd-button hd-button-wide"
        v-bind:class="{ 'hd-button_active': currentComponent == 'Inzicht' }"
        v-on:click="showInzicht"
        >
        Inzicht
      </div>
    </div>

    <div class="hd-kop-rechts">
        <div class="hd-button hd-button-narrow hd-rechts"
             v-on:click="logoutUser"
             >
          <i class="material-icons">
            lock
          </i>
        </div>
        <div class="hd-button hd-button-narrow hd-rechts"
             v-bind:class="{ 'hd-button_active': currentComponent == 'Configuratie' }"
             v-on:click="showConfiguratie"
             >
          <i class="material-icons">
            settings_applications
          </i>
        </div>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      title: 'Fin'
    }
  },

  computed: {
    authenticated () {
      return this.$store.getters['auth/authenticated']
    },
    currentuser () {
      return this.$store.getters['auth/currentuser']
    },
    currentComponent () {
      return this.$store.getters['nav/currentComponent']
    }

  },

  methods: {
    logoutUser: function() {
      this.$store.dispatch('auth/logoutUser');
      this.$store.commit('nav/currentComponent', '');
      this.$router.push('/login');
    },
    showBoekingen: function() {
      this.$store.commit('nav/currentComponent', 'Boekingen');
      this.$router.push('/boekingen');
    },
    showImporteren: function() {
      this.$store.commit('nav/currentComponent', 'Importeren');
      this.$router.push('/importeren');
    },
    showInzicht: function() {
      this.$store.commit('nav/currentComponent', 'Inzicht');
      this.$router.push('/inzicht');
    },
    showConfiguratie: function() {
      this.$store.commit('nav/currentComponent', 'Configuratie');
      this.$router.push('/configuratie');
    }
  }
}
</script>

<style scoped>


.hd-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 600;
    background-color: var(--overflow);

    box-sizing: border-box;
    border-bottom: 3px solid var(--accent-1-full);

    display: flex;
    flex-flow: row nowrap;
}
.hd-rechts {
  justify-content: flex-end;
}

.hd-kop-links {
  display: flex;
  flex-flow: row nowrap;
  margin: 0px 0px 0px 10px;
  width: calc(100% - 400px);
}

.hd-kop-rechts {
  width: 400px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
}

.hd-button-wide {
  padding: 10px 20px 10px 20px;
}

.hd-button-narrow {
  padding: 10px;
}

.hd-button {
  box-sizing: border-box;
  border: none;
  color: var(--text-main);
  height: 42px;
  padding: 10px 15px 10px 15px;
  margin: 5px 2px 0px 2px;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
}

.hd-button:hover {
  border-radius: 8px 8px 0px 0px;
  background-color: var(--accent-1-hover);
  color: var(--text-main);
  cursor: pointer;
}

.hd-button_active {
  border-radius: 8px 8px 0px 0px;
  background-color: var(--accent-1-full);
  color: var(--text-main-brighter);
  cursor:default;
}

.hd-button_active:hover {
  background-color: var(--accent-1-full);
  color: var(--text-main-brighter);
  cursor:default;
}

</style>
