// Store module rekening //
import Vue   from 'vue';
import axios from 'axios';
const  axiosUrl = '/api/data/v1/rekening';

  // TODO: UpdateIsAktiefChangeCounter vervangen door een meer generieke oplossing //

const stateInit = {
  sortOrder: [0],
  rekeningen: {
    0 : {
      id: 0,
      naam: 'default',
      nummer: 'default',
      partij: 'default',
      opmerking: null,
      isAktief: true,
      isInFilter: true,
      isEdited: false,
      isDefaultSelectie: false,
      beginsaldo: null,
      saldodatum: null,
      datumeersteboeking: null,
      datumlaatsteboeking: null,
      aantalboekingen: 0,
      huidigsaldo: 0
   }
  },
  isEditedId: '',
  isInvoerschermTonen: false,
  isAktiefChangeCounter: 0,
  isEditschermTonen: false,
}

const state = stateInit;

const getters = {

  rekeningen(state) {
    let larrRekeningen = [];
    state.sortOrder.forEach( (item) => {
      larrRekeningen.push(state.rekeningen[item]);
    });
    return larrRekeningen;
  },

  rekeningNamen(state) {
    let larrNamen = [];
    state.sortOrder.forEach((item) => {
      larrNamen.push(state.rekeningen[item].naam.toLowerCase());
    });
    return larrNamen;
  },

  rekeningEdited(state) {
    return state.rekeningen[state.isEditedId];
  },

  isAktiefChangeCounter(state) {
    return state.isAktiefChangeCounter;
  },

  isInvoerschermTonen(state) {
    return state.isInvoerschermTonen;
  },

  isEditschermTonen(state) {
    return state.isEditschermTonen;
  },

}


const mutations = {

  // Internal only //

  __setDefault(state, id) {
    for (var i in state.rekeningen) {
      state.rekeningen[i].isDefaultSelectie = false;
    };
    state.rekeningen[id].isDefaultSelectie = true;
  },

  __rekeningDelete(state, id) {
    Vue.delete(state.rekeningen, id);
    // Remove from the sort order as well
    var index = state.sortOrder.indexOf(id);
    if (index > -1) {
        state.sortOrder.splice(index, 1);
    }
  },

  __rekeningToevoegen(state, rekening) {
    Vue.set(state.rekeningen, rekening.id, rekening);
  },

  __wijzigingenOpslaan(state, rekening) {
    Vue.set(state.rekeningen[rekening.id], 'naam', rekening.naam)
    Vue.set(state.rekeningen[rekening.id], 'nummer', rekening.nummer)
    Vue.set(state.rekeningen[rekening.id], 'partij', rekening.partij)
    Vue.set(state.rekeningen[rekening.id], 'beginsaldo', rekening.beginsaldo)
    Vue.set(state.rekeningen[rekening.id], 'saldodatum', rekening.saldodatum)
    Vue.set(state.rekeningen[rekening.id], 'opmerking', rekening.opmerking)
  },

  __rekeningToevoegenAanSortOrder(state, id) {
    state.sortOrder.push(id);
  },

  __toggleIsInFilter(state, id) {
    state.rekeningen[id].isInFilter = !state.rekeningen[id].isInFilter;
  },

  __toggleIsAktief(state, id) {
    state.rekeningen[id].isAktief = !state.rekeningen[id].isAktief;
  },

  __UpdateIsAktiefChangeCounter(state) {
    state.isAktiefChangeCounter += 1;
  },

  __resetIsEditedId(state) {
    state.isEditedId = '';
  },

  // External //

  setIsEditedId(state, id) {
    state.isEditedId = id;
  },

  toggleIsInvoerschermTonen(state) {
    state.isInvoerschermTonen = !state.isInvoerschermTonen;
  },

  toggleIsEditschermTonen(state) {
    state.isEditschermTonen = !state.isEditschermTonen;
  },

}

/* routes
  + get    /
  + delete /:id/user/:user
  + post   /user/:user

  - put    /:id/user/:user   nog uit te werken

  + put    /setdefault/:id
  + put    /toggleisinfilter/:id
  + put    /deaktiveer/:id
  + put    /aktiveer/:id


*/


const actions = {

  initRekeningen  ({commit, rootGetters}, payload) {
    const url = axiosUrl
    axios.get(url)
    .then(response => {

     // TODO: sortorder zelf afleiden uit de data //
      // Fill the sortOrder. Dit gaat er van uit dat de data gesorteerd wordt aangeleverd.
      response.data.forEach( (item) => {
        commit('__rekeningToevoegenAanSortOrder', item.id);
      });

      // Create the rekeningen object //
      response.data.forEach( (item) => {
        let rekening = {
          id: item.id,
          naam: item.naam,
          nummer: item.nummer,
          partij: item.partij,
          opmerking: item.opmerking,
          isAktief: item.isAktief,
          isInFilter: item.isInFilter,
          isDefaultSelectie: item.isDefaultSelectie,
          isEdited: false,
          beginsaldo: item.beginsaldo,
          saldodatum: item.saldodatum,
          datumeersteboeking: item.datumeersteboeking,
          datumlaatsteboeking: item.datumlaatsteboeking,
          aantalboekingen: item.aantalboekingen,
          huidigsaldo: item.huidigsaldo
        };
        commit('__rekeningToevoegen', rekening);
      });

      // Remove the default item //
      commit('__rekeningDelete', 0);
    })

    .catch(
      error => {
        console.log(error);
      }
    )
  },


  rekeningOpvoeren ({commit, rootGetters}, payload) {
    let element = {};
    let newId = '';
    let lblnIsDefaultSelectie = false;
    // var params = new URLSearchParams();
    const url = axiosUrl + '/user/' + rootGetters['auth/currentuser']
    const newRekening = {
      "naam"   : payload.rekening.naam,
      "nummer" : payload.rekening.nummer,
      "partij" : payload.rekening.partij
    }
    axios.post(url, newRekening)
    .then(response => {

    // TODO: check response for error text first!!!!!

      // add as default if it is the first one te be added //
      // TODO nog toevoegen aan Stored Procedure
      // Werkt niet bij leeg object, duh!!!! //
      // console.log('Het aantal rekeningen = ' + response.data.length);
      //  if (response.data.length = 1) {
      //    lblnIsDefaultSelectie = true;
      //  };

      newId = response.data;
      // Add the element to the sort order as last row //

      // Add new local element to state.rekeningen //
      var rekening = {
        id: newId,
        naam: payload.rekening.naam,
        nummer: payload.rekening.nummer,
        partij: payload.rekening.partij,
        opmerking: '-',
        isAktief: true,
        isInFilter: true,
        isEdited: false,
        isDefaultSelectie: lblnIsDefaultSelectie,
        beginsaldo: 0,
        saldodatum: null,
        datumeersteboeking: 'Nvt',
        datumlaatsteboeking: 'Nvt',
        aantalboekingen: 0,
        huidigsaldo: 0
      };

      commit('__rekeningToevoegen', rekening);
      commit('__rekeningToevoegenAanSortOrder', newId);
      // Sluit het invoerscherm als alles verwerkt is.
      commit('toggleIsInvoerschermTonen');
    })
    .catch(
      error => {
        console.log(error);
      }
    )
  },


  editAnnuleren ({commit}) {
    commit('toggleIsEditschermTonen');
    commit('__resetIsEditedId');
  },


  wijzigingenOpslaan ({commit, rootGetters}, payload) {
    const url = axiosUrl + '/' + payload.rekening.id + '/user/' + rootGetters['auth/currentuser']
    const rekening = {
      "naam"       : payload.rekening.naam,
      "nummer"     : payload.rekening.nummer,
      "partij"     : payload.rekening.partij,
      "beginsaldo" : payload.rekening.beginsaldo,
      "saldodatum" : payload.rekening.saldodatum,
      "opmerking"  : payload.rekening.opmerking
    }
    axios.put(url, rekening)
    .then(response => {
      // TODO: check response for error text first!!!!!
      commit('__wijzigingenOpslaan', payload.rekening);
      // Sluit het scherm na het opslaan van de wijzigingen //
      commit('toggleIsEditschermTonen');
      commit('__resetIsEditedId');
    })
    .catch(
      error => {
        console.log(error);
      }
    )
  },


  rekeningVerwijderen ({commit, rootGetters}, payload) {
    const url = axiosUrl + '/' + payload.id + '/user/' + rootGetters['auth/currentuser']
    // var params = new URLSearchParams();
    // axios.delete(url, params)
    axios.delete(url)
    .then(response => {
      commit('__rekeningDelete', payload.id);
      // Sluit het scherm na verwijderen van de rekening //
      commit('toggleIsEditschermTonen');
      commit('__resetIsEditedId');
    })
    .catch(
      error => {
        console.log(error);
      }
    )
  },


  setDefault ({commit, rootGetters}, payload) {
    const url = axiosUrl + '/setdefault/' + payload.id
    // var params = new URLSearchParams();
    // axios.put(url, params)
    axios.put(url)
    .then(response => {
    // TODO: check response for error text first!!!!!
      commit('__setDefault', payload.id);
    })
    .catch(
      error => {
        console.log(error);
      }
    )
  },


  toggleIsInFilter ({commit, rootGetters}, payload) {
    const url = axiosUrl + '/toggleisinfilter/' + payload.id
    // var params = new URLSearchParams();
    // axios.put(url, params)
    axios.put(url)
    .then(response => {
      // TODO: check response for error text first!!!!!
      commit('__toggleIsInFilter', payload.id);
    })
    .catch(
      error => {
        console.log(error);
      }
    )
  },


  rekeningDeaktiveren ({commit, rootGetters}, payload) {
    const url = axiosUrl + '/deaktiveer/' + payload.id + '/user/' + rootGetters['auth/currentuser']
    // var params = new URLSearchParams();
    // axios.put(url, params)
    axios.put(url)
    .then(response => {
      // TODO: check response for error text first!!!!!
      commit('__toggleIsAktief', payload.id);
      commit('__UpdateIsAktiefChangeCounter');
    })
    .catch(
      error => {
        console.log(error);
      }
    )
  },


  rekeningAktiveren ({commit, rootGetters}, payload) {
    const url = axiosUrl + '/aktiveer/' + payload.id + '/user/' + rootGetters['auth/currentuser']
    // var params = new URLSearchParams();
    // axios.put(url, params)
    axios.put(url)
    .then(response => {
      // TODO: check response for error text first!!!!!
      commit('__toggleIsAktief', payload.id);
      commit('__UpdateIsAktiefChangeCounter');
    })
    .catch(
      error => {
        console.log(error);
      }
    )
  },
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
